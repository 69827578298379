import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  GET_OUTLET_LIST,
  GET_SSO_BULDING,
  GET_STATE_LIST,
  GET_COUNTRY_LIST,
  GET_STAFF_LIST,
  GET_USER_LIST,
  GET_SSO_DEPARTMENT,
} from "../constants/SSOData"
import { ResultSSOData } from "../actions/SSOData"
import SSOService from "services/SSOService"

//generator
export function* getOutletListAPI() {
  yield takeEvery(GET_OUTLET_LIST, function* ({ payload }) {
    const userInfo = JSON.parse(localStorage.getItem("userinfo"))

    payload?.setLoading && payload.setLoading(true)

    payload.search = { ...payload.search, userInfo }
    try {
      const group = yield call(SSOService.getOutLetList, payload)
      if (group.alert) {
      } else {
        group.type = "outLetList"
        yield put(ResultSSOData(group))
      }
    } catch (err) {
    } finally {
      payload?.setLoading && payload.setLoading(false)
    }
  })
}

export function* getSsoBuildingAPI() {
  yield takeEvery(GET_SSO_BULDING, function* ({ payload }) {
    try {
      const group = yield call(SSOService.getSsoBuildings, payload)
      if (group.alert) {
      } else {
        group.type = "ssoBuilding"
        yield put(ResultSSOData(group))
      }
    } catch (err) {
    } finally {
      payload?.setLoading && payload.setLoading(false)
    }
  })
}

export function* getSsoDepartmentAPI() {
  yield takeEvery(GET_SSO_DEPARTMENT, function* ({ payload }) {
    try {
      const group = yield call(SSOService.getSsoDepartments, payload)
      if (group.alert) {
      } else {
        group.type = "ssoDepartment"
        yield put(ResultSSOData(group))
      }
    } catch (err) {
    } finally {
      payload?.setLoading && payload.setLoading(false)
    }
  })
}

export function* getStateListAPI() {
  yield takeEvery(GET_STATE_LIST, function* ({ payload }) {
    try {
      const group = yield call(SSOService.getStateList, payload)
      if (group.alert) {
      } else {
        group.type = "stateList"
        yield put(ResultSSOData(group))
      }
    } catch (err) {}
  })
}

export function* getCountryListAPI() {
  yield takeEvery(GET_COUNTRY_LIST, function* ({ payload }) {
    try {
      const group = yield call(SSOService.getCountryList, payload)
      if (group.alert) {
      } else {
        group.type = "countryList"
        yield put(ResultSSOData(group))
      }
    } catch (err) {}
  })
}

export function* getStaffListAPI() {
  yield takeEvery(GET_STAFF_LIST, function* ({ payload }) {
    try {
      const group = yield call(SSOService.getStaffList, payload)
      if (group.alert) {
      } else {
        group.type = "staffList"
        yield put(ResultSSOData(group))
      }
    } catch (err) {
    } finally {
      payload?.setLoading && payload.setLoading(false)
    }
  })
}

export function* getUserListAPI() {
  yield takeEvery(GET_USER_LIST, function* ({ payload }) {
    try {
      const group = yield call(SSOService.getUserList, payload)
      if (group.alert) {
      } else {
        group.type = "userList"
        yield put(ResultSSOData(group))
      }
    } catch (err) {}
  })
}

export default function* rootSaga() {
  yield all([
    fork(getOutletListAPI),
    fork(getSsoBuildingAPI),
    fork(getSsoDepartmentAPI),
    fork(getStateListAPI),
    fork(getCountryListAPI),
    fork(getStaffListAPI),
    fork(getUserListAPI),
  ])
}
