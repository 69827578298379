import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { resultCustomerType } from "redux/actions/CustomerType"
import { GET_LIST_CUSTOMER_TYPE } from "redux/constants/CustomerType"
import CustomerTypeService from "services/CustomerTypeService"

export function* listCustomerTypeAPI() {
  yield takeEvery(GET_LIST_CUSTOMER_TYPE, function* () {
    const payload = {}
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(CustomerTypeService.getListCustomerType, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultCustomerType(group))
      }
    } catch (err) {}
  })
}

export default function* rootSaga() {
  yield all([fork(listCustomerTypeAPI)])
}
