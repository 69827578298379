import { isArray } from "lodash"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setIsVoucherLoading,
  alertVouchers,
  resultVouchers,
  setDeleteVoucher,
  setUpdateVoucher,
} from "redux/actions/Voucher"
import {
  CREATE_VOUCHERS,
  GET_LIST_VOUCHERS,
  DELETE_VOUCHER,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_BATCH,
} from "redux/constants/Voucher"
import VoucherService from "services/VoucherService"

export function* createVouchersAPI() {
  yield takeEvery(CREATE_VOUCHERS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(VoucherService.createVouchers, payload)
      if (group.alert) {
        yield put(alertVouchers({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(`Upload Pronto Voucher in Voucher Listing`)
        )

        group.type = "CREATE"
        yield put(
          alertVouchers({
            success: true,
            message: `Upload completed. Total ${group.data.TotalRecords} records have been uploaded successfully.`,
          })
        )
      }
    } catch (err) {
      yield put(
        alertVouchers({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* listVouchersAPI() {
  yield takeEvery(GET_LIST_VOUCHERS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(VoucherService.getListVoucher, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultVouchers(group))
      }
    } catch (err) {}
  })
}

export function* updateVoucherAPI() {
  yield takeEvery(UPDATE_VOUCHER, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const voucher = yield call(VoucherService.updateVoucher, payload)
      if (voucher.alert) {
        yield put(
          alertVouchers({ success: false, message: "Update Voucher failed" })
        )
      } else {
        yield put(
          createLoginActivity(
            `Update Voucher in Voucher Listing. VoucherNo - ${payload.VoucherNo}`
          )
        )

        yield put(setUpdateVoucher(voucher.data))
        yield put(
          alertVouchers({
            success: true,
            message: `Voucher successfully updated`,
          })
        )
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        yield put(
          alertVouchers({
            success: false,
            message: isArray(message) ? message[0] : message,
          })
        )
      } else {
        yield put(
          alertVouchers({
            success: false,
            message: error.message,
          })
        )
      }
    }
  })
}

export function* updateVoucherBatchAPI() {
  yield takeEvery(UPDATE_VOUCHER_BATCH, function* ({ payload }) {
    yield put(setIsVoucherLoading(true))
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const voucher = yield call(VoucherService.updateVoucherBatch, payload)
      if (voucher.alert) {
        yield put(
          alertVouchers({ success: false, message: "Update Voucher failed" })
        )
      } else {
        yield put(
          alertVouchers({
            success: true,
            message: `Voucher successfully updated`,
          })
        )

        yield put(setIsVoucherLoading(false))

        // Close Update Expiry Date Modal & Reset Voucher Listing
        payload.callback()
      }
    } catch (error) {
      yield put(setIsVoucherLoading(false))

      if (error?.response?.data) {
        const { message } = error.response.data
        yield put(
          alertVouchers({
            success: false,
            message: isArray(message) ? message[0] : message,
          })
        )
      } else {
        yield put(
          alertVouchers({
            success: false,
            message: error.message,
          })
        )
      }
    }
  })
}

export function* deleteVoucherAPI() {
  yield takeEvery(DELETE_VOUCHER, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const voucher = yield call(VoucherService.deleteVoucher, payload)
      if (voucher.alert) {
        yield put(
          alertVouchers({ success: false, message: "Delete Voucher failed" })
        )
      } else {
        yield put(
          createLoginActivity(
            `Delete Voucher in Voucher Listing. VoucherNo - ${payload.VoucherNo}`
          )
        )

        yield put(setDeleteVoucher(payload))
        yield put(
          alertVouchers({
            success: true,
            message: `Voucher successfully deleted`,
          })
        )
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        yield put(
          alertVouchers({
            success: false,
            message: isArray(message) ? message[0] : message,
          })
        )
      } else {
        yield put(
          alertVouchers({
            success: false,
            message: error.message,
          })
        )
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listVouchersAPI),
    fork(createVouchersAPI),
    fork(updateVoucherAPI),
    fork(updateVoucherBatchAPI),
    fork(deleteVoucherAPI),
  ])
}
