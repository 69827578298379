import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  CREATE_CUSTOMER_TASK,
  GET_LIST_CUSTOMER_TASK,
  GET_CUSTOMER_TASK,
  UPDATE_CUSTOMER_TASK,
  DELETE_CUSTOMER_TASK,
  UPDATE_CUSTOMER_TASK_AND_PROGRESS_LOGS,
} from "../constants/CustomerTask"
import {
  setCustomerTaskLoading,
  resultCustomerTask,
  alertCustomerTask,
} from "../actions/CustomerTask"
import CustomerTaskService from "services/CustomerTaskService"
import CommonService from "services/CommonService"
import { isArray } from "lodash"
import { AUTH_TOKEN } from "redux/constants/Auth"
import { alertTaskProgress } from "redux/actions/TaskProgress"
import TaskProgressService from "services/TaskProgressService"

const getFileType = (fileType) => {
  if (fileType.indexOf("image") !== -1) {
    return "Photo"
  } else if (fileType.indexOf("video") !== -1) {
    return "Video"
  } else {
    return "Document"
  }
}

export function* createCustomerTaskAPI() {
  yield takeEvery(CREATE_CUSTOMER_TASK, function* ({ payload }) {
    let auth_token = localStorage.getItem(AUTH_TOKEN)
    payload.access_token = auth_token

    try {
      const group = yield call(CustomerTaskService.createCustomerTask, payload)
      if (group.alert) {
        yield put(alertCustomerTask({ success: false, message: group.alert }))
      } else {
        for (let file of payload.fileList) {
          let files = file?.name.split(".")
          let fileName = files[0]
          let fileExtension = files.pop()

          let fdata = new FormData()
          fdata.append("ObjectId", group.data.TaskId)
          fdata.append("ObjectType", "TaskId")
          fdata.append("Type", getFileType(file.type))
          fdata.append("FileStream", file)
          fdata.append("FileName", fileName)
          fdata.append("FileExtension", fileExtension)
          fdata.append("Title", payload.Name)
          fdata.append("Description", payload.Description)

          yield call(CommonService.uploadImageMediasInternal, fdata)
        }

        /** Add Login Activity */
        yield put(
          createLoginActivity(
            `Add Task in Customer Task. TaskId - ${group.data.TaskId}`
          )
        )

        group.type = "CREATE"
        yield put(resultCustomerTask(group))
        yield put(
          alertCustomerTask({
            success: true,
            message: "A new Customer Task has been created",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCustomerTask({
          success: false,
          message: isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data.message ?? "Something went wrong",
        })
      )
    }
  })
}

export function* listCustomerTaskAPI() {
  yield takeEvery(GET_LIST_CUSTOMER_TASK, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setCustomerTaskLoading(true))
      const group = yield call(CustomerTaskService.getListCustomerTask, payload)
      if (group.alert) {
        // do nothing
      } else {
        group.type = "LIST"
        yield put(resultCustomerTask(group))
      }
    } catch (err) {
      yield put(setCustomerTaskLoading(false))
    }
  })
}

export function* getCustomerTaskAPI() {
  yield takeEvery(GET_CUSTOMER_TASK, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(CustomerTaskService.getCustomerTask, payload)
      if (group.alert) {
        yield put(alertCustomerTask(group.alert))
      } else {
        yield put(resultCustomerTask(group.data))
      }
    } catch (err) {
      yield put(alertCustomerTask(err))
    }
  })
}

export function* updateCustomerTaskAPI() {
  yield takeEvery(UPDATE_CUSTOMER_TASK, function* ({ payload }) {
    let auth_token = localStorage.getItem(AUTH_TOKEN)
    payload.access_token = auth_token

    try {
      const group = yield call(CustomerTaskService.updateCustomerTask, payload)
      if (group.alert) {
        yield put(alertCustomerTask({ success: false, message: group.alert }))
      } else {
        const { oldFileList, fileList, TaskId, Name, Description } = payload

        let filesToUpload = fileList

        if ((oldFileList ?? []).length > 0) {
          const filesToDelete = oldFileList.filter(
            (oldFile) =>
              !fileList?.map((file) => file.uid)?.includes(oldFile.uid)
          )
          for (let file of filesToDelete) {
            yield call(CommonService.deleteMedia, file.uid)
          }

          filesToUpload = fileList.filter(
            (file) =>
              !oldFileList?.map((oldFile) => oldFile.uid)?.includes(file.uid)
          )
        }

        for (let file of filesToUpload) {
          let files = file?.name.split(".")
          let fileName = files[0]
          let fileExtension = files.pop()

          let fdata = new FormData()
          fdata.append("ObjectId", TaskId)
          fdata.append("ObjectType", "TaskId")
          fdata.append("Type", getFileType(file.type))
          fdata.append("FileStream", file)
          fdata.append("FileName", fileName)
          fdata.append("FileExtension", fileExtension)
          fdata.append("Title", Name)
          fdata.append("Description", Description)

          yield call(CommonService.uploadImageMediasInternal, fdata)
        }

        /** Add Login Activity */
        yield put(
          createLoginActivity(
            `Update Task in Customer Task. TaskId - ${group.data.TaskId}`
          )
        )

        group.type = "UPDATE"
        group.data = payload
        yield put(resultCustomerTask(group))
        yield put(
          alertCustomerTask({
            success: true,
            message: "Customer Task successfully updated",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCustomerTask({
          success: false,
          message: isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data.message ?? "Something went wrong",
        })
      )
    }
  })
}

function compareAndUpdateLogs(currentArray, updatedArray) {
  // Helper function to find object by ProgressLogId
  const findByProgressLogId = (array, id) => {
    return array.find((item) => item.ProgressLogId === id)
  }

  const differences = []
  let hasChanges = false

  // Compare statuses and find differences
  currentArray.forEach((item1) => {
    const item2 = findByProgressLogId(updatedArray, item1.ProgressLogId)

    if (item2) {
      if (item1.Status !== item2.Status) {
        differences.push({
          ProgressLogId: item1.ProgressLogId,
          StatusInCurrent: item1.Status,
          StatusInUpdated: item2.Status,
        })
        hasChanges = true
      }
    } else {
      differences.push({
        ProgressLogId: item1.ProgressLogId,
        message: "Item not found in updated array",
      })
      hasChanges = true
    }
  })

  // Append items from currentArray to updatedArray if they don't exist in updatedArray
  currentArray.forEach((item1) => {
    if (!findByProgressLogId(updatedArray, item1.ProgressLogId)) {
      updatedArray.push(item1)
    }
  })

  return {
    differences,
    updatedArray,
    hasChanges,
  }
}

export function* updateCustomerTaskAndProgressLogsAPI() {
  yield takeEvery(
    UPDATE_CUSTOMER_TASK_AND_PROGRESS_LOGS,
    function* ({ payload }) {
      const {
        currentSelectedTask,
        updatedCustomerTaskData,
        updatedTaskProgressLogsData,
      } = payload

      let auth_token = localStorage.getItem(AUTH_TOKEN)
      payload.access_token = auth_token
      try {
        let group = {}

        if (
          currentSelectedTask.Status !== updatedCustomerTaskData.Status ||
          currentSelectedTask.SSOBuildingId !==
            updatedCustomerTaskData.SSOBuildingId ||
          currentSelectedTask.DueDate !== updatedCustomerTaskData.DueDate
        ) {
          const updatedCustomerTask = yield call(
            CustomerTaskService.updateCustomerTask,
            {
              ...updatedCustomerTaskData,
              access_token: auth_token,
            }
          )

          if (updatedCustomerTask.alert) {
            yield put(
              alertCustomerTask({
                success: false,
                message: updatedCustomerTask.alert,
              })
            )
          } else {
            yield put(createLoginActivity("Save Task Details in Dashboard"))
          }
        }

        const comparedTaskProgressLogs = compareAndUpdateLogs(
          currentSelectedTask.TaskProgressLog,
          updatedTaskProgressLogsData
        )

        if (comparedTaskProgressLogs.hasChanges) {
          for (const progressLog of comparedTaskProgressLogs.updatedArray) {
            const updatedTaskProgressLog = yield call(
              TaskProgressService.updateTaskProgress,
              {
                ...progressLog,
                access_token: auth_token,
              }
            )

            if (updatedTaskProgressLog.alert) {
              yield put(
                alertTaskProgress({
                  success: false,
                  message: updatedTaskProgressLog.alert,
                })
              )
            }
          }

          yield put(createLoginActivity("Save Task Progress Log in Dashboard"))

          yield put(
            alertCustomerTask({
              success: true,
              message: "Task Progress Log successfully updated",
            })
          )

          group.data = {
            TaskProgressLog: comparedTaskProgressLogs.updatedArray,
          }
        }

        group.type = "UPDATE"
        group.data = {
          ...updatedCustomerTaskData,
          TaskProgressLog:
            group.data?.TaskProgressLog ||
            updatedCustomerTaskData.TaskProgressLog,
        }

        yield put(resultCustomerTask(group))

        yield put(
          alertCustomerTask({
            success: true,
            message: "Customer Task successfully updated",
          })
        )
      } catch (err) {
        yield put(
          alertCustomerTask({
            success: false,
            message: isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data.message ?? "Something went wrong",
          })
        )
      }
    }
  )
}

export function* deleteCustomerTaskAPI() {
  yield takeEvery(DELETE_CUSTOMER_TASK, function* ({ payload }) {
    let auth_token = localStorage.getItem(AUTH_TOKEN)
    payload.access_token = auth_token

    try {
      const group = yield call(CustomerTaskService.deleteCustomerTask, payload)
      if (group.alert) {
        yield put(alertCustomerTask({ success: false, message: group.alert }))
      } else {
        group.type = "DELETE"
        group.data = payload
        yield put(resultCustomerTask(group))

        /** Add Login Activity */
        yield put(
          createLoginActivity(
            `Delete Task in Customer Task. TaskId - ${payload.id}`
          )
        )

        yield put(
          alertCustomerTask({
            success: true,
            message: "Customer Task has been deleted/inactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(alertCustomerTask({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listCustomerTaskAPI),
    fork(createCustomerTaskAPI),
    fork(getCustomerTaskAPI),
    fork(updateCustomerTaskAPI),
    fork(deleteCustomerTaskAPI),
    fork(updateCustomerTaskAndProgressLogsAPI),
  ])
}
