import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"
import moment from "moment"

const PointExpiryService = {}

PointExpiryService.getListPointExpiry = function (data) {
  let params = {
    BalanceType: "Jewel Club Points",
  }

  if (data?.rows) {
    params.Rows = data.rows
  }
  if (data?.page) {
    params.Page = data.page
  }
  if (data?.MembershipNo) {
    params.MembershipNo = data.MembershipNo
  }
  if (data?.MemberName) {
    params.FullName = data.MemberName
  }
  if (data?.MemberIdNo) {
    params.IdNo = data.MemberIdNo
  }
  if (data?.ContactNo) {
    params.ContactNo = data.ContactNo
  }
  if (data?.LastTransaction) {
    const month = moment(data.LastTransaction).format("M")
    const year = moment(data.LastTransaction).format("YYYY")
    params.YearFrom = String(year)
    params.YearTo = String(year)
    params.MonthFrom = String(month)
    params.MonthTo = String(month)
  }

  return fetch({
    url: "/point/expires/group/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: params,
  })
}

PointExpiryService.getListCustomerPointExpiries = function (data) {
  let params = {
    Page: 1,
    Rows: 10,
    BalanceType: "Jewel Club Points",
  }

  if (data?.rows) {
    params.Rows = data.rows
  }

  if (data?.page) {
    params.Page = data.page
  }

  if (data?.MembershipNo) {
    params.MembershipNo = data.MembershipNo
  }

  if (data?.Year) {
    params.Year = String(data.Year)
  }

  if (data?.Month) {
    params.Month = String(data.Month)
  }

  return fetch({
    url: "/point/expires/find-all",
    method: "post",
    data: params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

PointExpiryService.updateCustomerPointExpiry = function (data) {
  return fetch({
    url: "/point/expires",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      MembershipNo: data?.MembershipNo,
      Month: data?.Month,
      Year: data?.Year,
      TotalPointToExpire: data.TotalPointToExpire,
    },
  })
}

export default PointExpiryService
