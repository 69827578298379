import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const PointAdjustmentService = {}

PointAdjustmentService.createPointAdjustment = function (data) {
  return fetch({
    url: "/point/adjustments/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

PointAdjustmentService.getPointAdjustments = function (data) {
  return fetch({
    url: "/point/adjustments/find-all",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: {
      Rows: data.rows ?? 20,
      Page: data.page ?? 1,
      Search: JSON.stringify(data.search ?? {}),
      SortDirection: "desc",
    },
  })
}

PointAdjustmentService.getPointAdjustment = function (data) {
  return fetch({
    url: `/point/adjustments/find-one`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: { HistoryId: data.HistoryId },
  })
}

PointAdjustmentService.updatePointAdjustment = function (data) {
  return fetch({
    url: `/point/adjustments/update`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data,
  })
}

PointAdjustmentService.deletePointAdjustment = function (data) {
  return fetch({
    url: `/point/adjustments/delete`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: { HistoryId: data.HistoryId },
  })
}

export default PointAdjustmentService
