import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const ReferrerListingService = {}

ReferrerListingService.awardReferrer = function (data) {
  return fetch({
    url: `/point/award`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

export default ReferrerListingService
