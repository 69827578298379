import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  getCustomerAddressList,
  setCustomerAddressLoading,
  setCustomerAddressList,
  setCustomerAddressDetail,
  setCreateCustomerAddress,
  setUpdateCustomerAddress,
  setDeleteCustomerAddress,
} from "redux/actions/CustomerAddress"
import {
  GET_CUSTOMER_ADDRESS_LIST,
  GET_CUSTOMER_ADDRESS_DETAIL,
  CREATE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
} from "redux/constants/CustomerAddress"
import CustomerAddressService from "services/CustomerAddressService"

export function* createCustomerAddress() {
  yield takeEvery(CREATE_CUSTOMER_ADDRESS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setCustomerAddressLoading(true))

      const customerAddress = yield call(
        CustomerAddressService.createCustomerAddress,
        payload
      )
      if (customerAddress.alert) {
        setNotification({
          type: "error",
          message: "Create Customer Address Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Add Customer Address in View Customer. AddressId - ${customerAddress.data?.dataValues?.AddressId}`
          )
        )

        yield put(setCreateCustomerAddress(customerAddress.data.dataValues))
        setNotification({
          type: "success",
          message: "Create Customer Address Success",
        })
      }
    } catch (error) {
      yield put(setCustomerAddressLoading(false))

      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Customer Address Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Customer Address Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* getAllCustomerAddress() {
  yield takeEvery(GET_CUSTOMER_ADDRESS_LIST, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setCustomerAddressLoading(true))

      const customerAddress = yield call(
        CustomerAddressService.getListCustomerAddress,
        payload
      )
      if (customerAddress.alert) {
        setNotification({
          type: "error",
          message: "Get Customer Address Failed",
          description: "An error occured",
        })
      } else {
        yield put(setCustomerAddressList(customerAddress.data))
      }
    } catch (error) {
      yield put(setCustomerAddressLoading(false))

      setNotification({
        type: "error",
        message: "Get Customer Address Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getOneCustomerAddress() {
  yield takeEvery(GET_CUSTOMER_ADDRESS_DETAIL, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setCustomerAddressLoading(true))

      const customerAddress = yield call(
        CustomerAddressService.getCustomerAddress,
        payload
      )

      if (customerAddress.alert) {
        setNotification({
          type: "error",
          message: "Get Customer Address Failed",
          description: "An error occured",
        })
      } else {
        yield put(setCustomerAddressDetail(customerAddress.data))
      }
    } catch (error) {
      yield put(setCustomerAddressLoading(false))

      setNotification({
        type: "error",
        message: "Get Customer Address Failed",
        description: "An error occured",
      })
    }
  })
}

export function* updateCustomerAddress() {
  yield takeEvery(
    UPDATE_CUSTOMER_ADDRESS,
    function* ({ payload, isUpdatingPrimaryAddress }) {
      let auth_token = localStorage.getItem("auth_token")
      payload.access_token = auth_token
      try {
        yield put(setCustomerAddressLoading(true))

        const customerAddress = yield call(
          CustomerAddressService.updateCustomerAddress,
          payload
        )
        if (customerAddress.alert) {
          setNotification({
            type: "error",
            message: "Update Customer Address failed",
            description: "An error occured",
          })
        } else {
          yield put(setUpdateCustomerAddress(customerAddress.data.dataValues))

          if (isUpdatingPrimaryAddress) {
            yield put(
              getCustomerAddressList({
                CustomerId: customerAddress.data.dataValues.CustomerId,
              })
            )

            yield put(
              createLoginActivity(
                `Update Customer Primary Address in View Customer. AddressId - ${customerAddress.data.dataValues.AddressId}`
              )
            )
          } else {
            yield put(
              createLoginActivity(
                `Update Customer Address in View Customer. AddressId - ${customerAddress.data.dataValues.AddressId}`
              )
            )
          }

          setNotification({
            type: "success",
            message: "Customer Address successfully updated",
          })
        }
      } catch (error) {
        yield put(setCustomerAddressLoading(false))

        if (error?.response?.data) {
          const { message } = error.response.data
          setNotification({
            type: "error",
            message: "Update Customer Address Failed",
            description: message,
          })
        } else {
          setNotification({
            type: "error",
            message: "Update Customer Address Failed",
            description: error.message,
          })
        }
      }
    }
  )
}

export function* deleteCustomerAddress() {
  yield takeEvery(DELETE_CUSTOMER_ADDRESS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setCustomerAddressLoading(true))

      const customerAddress = yield call(
        CustomerAddressService.deleteCustomerAddress,
        payload
      )
      if (customerAddress.alert) {
        setNotification({
          type: "error",
          message: "Delete Customer Address failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Customer Address in View Customer. AddressId - ${payload.AddressId}`
          )
        )

        yield put(setDeleteCustomerAddress(payload))
        setNotification({
          type: "success",
          message: "Customer Address successfully deleted",
        })
      }
    } catch (error) {
      yield put(setCustomerAddressLoading(false))

      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Customer Address Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Customer Address Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createCustomerAddress),
    fork(getAllCustomerAddress),
    fork(getOneCustomerAddress),
    fork(updateCustomerAddress),
    fork(deleteCustomerAddress),
  ])
}
