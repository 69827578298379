import moment from "moment"
import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const TransactionHistoryService = {}

TransactionHistoryService.createTransactionSummary = function (data) {
  return fetch({
    method: "post",
    url: `/customer/memberships/transaction-summaries/add`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

TransactionHistoryService.getTransactionSummaryList = function (data) {
  const { rows, page, search } = data

  const payload = {
    Rows: rows ?? 10,
    Page: page ?? 1,
    Search: JSON.stringify(search),
    SortOption: ["PerformedAt", "InvoiceNo", "TotalAmount"],
    SortDirection: ["ASC", "ASC", "DESC"],
  }

  return fetch({
    method: "post",
    url: `/customer/transaction-summaries/find-all`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

TransactionHistoryService.getCustomerTransactionReportList = function (data) {
  const searchQuery = {}

  if (data?.TransactionDate) {
    searchQuery.TransactionDateFrom = moment(data?.TransactionDate[0]).format(
      "YYYY-MM-DD"
    )
    searchQuery.TransactionDateTo = moment(data?.TransactionDate[1]).format(
      "YYYY-MM-DD"
    )
  }
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.InvoiceNo) searchQuery.InvoiceNo = data.InvoiceNo
  if (data?.SalesPersonCode)
    searchQuery.SalesPersonCode = data.SalesPersonCode.toUpperCase()
  if (data?.OutletCode) {
    if (data.OutletCode !== "HQ") {
      searchQuery.OutletCode = data.OutletCode
    }
  }
  if (data?.ProductType) searchQuery.ProductType = data.ProductType
  if (data?.ProductGroup) searchQuery.ProductGroup = data.ProductGroup
  if (data?.AmountFrom) searchQuery.AmountFrom = Number(data.AmountFrom)
  if (data?.AmountTo) searchQuery.AmountTo = Number(data.AmountTo)

  const payload = { ...searchQuery, ExcludeCancelTrxYN: "N" }

  return fetch({
    method: "post",
    url: `/reporting/customer-transaction-report`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

TransactionHistoryService.getTransactionSummary = function (data) {
  const { SalesOrder, TransactionType } = data

  return fetch({
    method: "post",
    url: `/customer/transaction-summaries/${
      TransactionType === "N"
        ? "normal"
        : TransactionType === "C"
        ? "cancel"
        : ""
    }/find-one`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { SalesOrder },
  })
}

TransactionHistoryService.updateTransactionSummary = function (data) {
  const { TransactionType } = data
  return fetch({
    method: "post",
    url: `/customer/transaction-summaries/${
      TransactionType === "N"
        ? "normal"
        : TransactionType === "C"
        ? "cancel"
        : ""
    }/update`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

TransactionHistoryService.createTransactionDetail = function (data) {
  return fetch({
    method: "post",
    url: `/customer/transaction-details/add`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

TransactionHistoryService.getTransactionDetailList = function (data) {
  const { rows, page, search } = data

  const payload = {
    Rows: rows ?? 10,
    Page: page ?? 1,
    Search: JSON.stringify(search),
  }

  return fetch({
    method: "post",
    url: `/customer/transaction-details/find-all`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

TransactionHistoryService.getTransactionDetail = function (data) {
  const { SalesOrder, TransactionType, SequenceNo } = data
  return fetch({
    method: "post",
    url: `/customer/transaction-details/${
      TransactionType === "N"
        ? "normal"
        : TransactionType === "C"
        ? "cancel"
        : ""
    }/find-one`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { SalesOrder, SequenceNo },
  })
}

TransactionHistoryService.updateTransactionDetail = function (data) {
  const { TransactionType, OldSequenceNo } = data
  return fetch({
    method: "post",
    url: `/customer/transaction-details/${
      TransactionType === "N"
        ? "normal"
        : TransactionType === "C"
        ? "cancel"
        : ""
    }/update`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...data, SequenceNo: OldSequenceNo },
  })
}

export default TransactionHistoryService
