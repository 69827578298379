import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const MembershipLevelService = {}

MembershipLevelService.createMembershipLevel = function (data) {
  return fetch({
    method: "post",
    url: `/member/level-histories/add`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

MembershipLevelService.getMembershipLevels = function (data) {
  const { rows, page, search } = data
  return fetch({
    method: "post",
    url: `/member/level-histories/find-all`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: rows ?? 20,
      Page: page ?? 1,
      Search: JSON.stringify(search ?? {}),
    },
  })
}

MembershipLevelService.getMembershipsForDowngrade = function (data) {
  const { rows, page, search } = data
  return fetch({
    method: "post",
    url: `/customer/memberships/downgrade-listing`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: rows ?? 20,
      Page: page ?? 1,
      Search: JSON.stringify(search ?? {}),
    },
  })
}

MembershipLevelService.getMembershipLevelByMember = function (data) {
  const { MembershipNo, rows, page, search } = data
  return fetch({
    method: "post",
    url: `/member/level-histories/find-one`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: rows ?? 20,
      Page: page ?? 1,
      Search: JSON.stringify(search ?? {}),
      MembershipNo,
    },
  })
}

MembershipLevelService.updateMembershipLevel = function (data) {
  const { LevelHistoryId } = data
  return fetch({
    method: "put",
    url: `/member/level-histories/${LevelHistoryId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

MembershipLevelService.approveMembershipLevel = function (data) {
  const { LevelHistoryId } = data
  return fetch({
    method: "put",
    url: `/member/level-histories/${LevelHistoryId}/approved`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

MembershipLevelService.rejectMembershipLevel = function (data) {
  const { LevelHistoryId } = data
  return fetch({
    method: "put",
    url: `/member/level-histories/${LevelHistoryId}/rejected`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

MembershipLevelService.deleteMembershipLevel = function (data) {
  const { LevelHistoryId } = data
  return fetch({
    method: "delete",
    url: `/member/level-histories/${LevelHistoryId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default MembershipLevelService
