import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import {
  CREATE_CUSTOMER_TAG,
  DELETE_CUSTOMER_TAG,
  GET_LIST_CUSTOMER_TAG,
} from "../constants/CustomerTag"
import { alertCustomerTag, resultCustomerTag } from "../actions/CustomerTag"
import CustomerTagService from "services/CustomerTagService"
import { isArray } from "lodash"
import {
  AUTH_TOKEN,
  CLIENT_IP_ADDRESS,
  UNIQUE_SESSION_ID,
} from "redux/constants/Auth"
import AuthService from "services/AuthService"

//generator
export function* createCustomerTagAPI() {
  yield takeEvery(CREATE_CUSTOMER_TAG, function* ({ payload }) {
    const sessionId = localStorage.getItem(UNIQUE_SESSION_ID)
    const userIP = localStorage.getItem(CLIENT_IP_ADDRESS)

    payload.access_token = localStorage.getItem(AUTH_TOKEN)
    let userInfo = JSON.parse(localStorage.getItem("userinfo"))

    try {
      const group = yield call(CustomerTagService.createCustomerTag, payload)
      if (group.alert) {
        yield put(alertCustomerTag({ success: false, message: group.alert }))
      } else {
        /** Add Login Activity */
        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: `Add New Customer Tag in General Info. TagId - ${group.data.TagId}`,
        })

        group.type = "CREATE"
        yield put(resultCustomerTag(group))
      }
    } catch (err) {
      yield put(
        alertCustomerTag({
          success: false,
          message: isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data.message ?? "Something went wrong",
        })
      )
    }
  })
}

export function* listCustomerTagAPI() {
  yield takeEvery(GET_LIST_CUSTOMER_TAG, function* ({ payload }) {
    let auth_token = localStorage.getItem(AUTH_TOKEN)
    payload.access_token = auth_token
    try {
      const group = yield call(CustomerTagService.getListCustomerTag, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultCustomerTag(group))
      }
    } catch (err) {}
  })
}

export function* deleteCustomerTagAPI() {
  yield takeEvery(DELETE_CUSTOMER_TAG, function* ({ payload }) {
    const sessionId = localStorage.getItem(UNIQUE_SESSION_ID)
    const userIP = localStorage.getItem(CLIENT_IP_ADDRESS)

    let auth_token = localStorage.getItem(AUTH_TOKEN)
    payload.access_token = auth_token
    let userInfo = JSON.parse(localStorage.getItem("userinfo"))

    try {
      const group = yield call(CustomerTagService.deleteCustomerTag, payload)
      if (group.alert) {
        yield put(alertCustomerTag({ success: false, message: group.alert }))
      } else {
        /** Add Login Activity */
        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: `Delete Customer Tag in General Info. TagId - ${payload.TagId}`,
        })

        group.type = "DELETE"
        group.data = payload
        yield put(resultCustomerTag(group))
        yield put(
          alertCustomerTag({
            success: true,
            message: "Customer Tag has been deleted/inactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(alertCustomerTag({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listCustomerTagAPI),
    fork(createCustomerTagAPI),
    fork(deleteCustomerTagAPI),
  ])
}
