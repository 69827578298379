import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  GET_CUSTOMER_ACTIVITY_TYPE,
  GET_LIST_CUSTOMER_ACTIVITY_TYPE,
  GET_LIST_CUSTOMER_ACTIVITY_TYPE_CODE,
  CREATE_CUSTOMER_ACTIVITY_TYPE,
  UPDATE_CUSTOMER_ACTIVITY_TYPE,
  DELETE_CUSTOMER_ACTIVITY_TYPE,
} from "../constants/CustomerActivityType"
import {
  getResultCustomerActivityType,
  setAlertActivityType,
} from "../actions/CustomerActivityType"
import CustomerActivityTypeService from "services/CustomerActivityTypeService"
import setNotification from "components/shared-components/Notification"
import { createLoginActivity } from "redux/actions/Auth"

export function* listCustomerActivityTypesAPI() {
  yield takeEvery(GET_LIST_CUSTOMER_ACTIVITY_TYPE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const group = yield call(
        CustomerActivityTypeService.getListCustomerActivityType,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Listing Failed",
          description: "An error occured",
        })
      } else {
        group.type = "LIST"
        yield put(getResultCustomerActivityType(group))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Listing Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getListCustomerTypeCode() {
  yield takeEvery(GET_LIST_CUSTOMER_ACTIVITY_TYPE_CODE, function* () {
    const payload = { page: 1, rows: Number.MAX_SAFE_INTEGER }
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityTypeService.getListCustomerActivityType,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Listing Failed",
          description: "An error occured",
        })
      } else {
        group.type = "CODE LIST"
        yield put(getResultCustomerActivityType(group))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Listing Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getCustomerActivityType() {
  yield takeEvery(GET_CUSTOMER_ACTIVITY_TYPE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityTypeService.getCustomerActivityType,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Listing Failed",
          description: "An error occured",
        })
      } else {
        group.type = "DETAIL"
        yield put(getResultCustomerActivityType(group))
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Listing Failed",
        description: "An error occured",
      })
    }
  })
}

export function* createCustomerActivityType() {
  yield takeEvery(CREATE_CUSTOMER_ACTIVITY_TYPE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityTypeService.createCustomerActivityType,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Create Activity Type Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Add Activity Type in Activity Type. ActivityCode - ${group.data.ActivityCode}`
          )
        )

        group.type = "CREATE"
        yield put(getResultCustomerActivityType(group))
        yield put(setAlertActivityType({ success: true }))
        setNotification({
          type: "success",
          message: "Create Activity Type Success",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Activity Type Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Activity Type Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* updateCustomerActivityType() {
  yield takeEvery(UPDATE_CUSTOMER_ACTIVITY_TYPE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityTypeService.updateCustomerActivityType,
        payload
      )

      if (group.alert) {
        setNotification({
          type: "error",
          message: "Update Activity Type failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Update Activity Type in Activity Type. ActivityCode - ${group.data.ActivityCode}`
          )
        )

        const updateData = {
          type: "UPDATE",
          data: { ...payload },
        }
        yield put(getResultCustomerActivityType(updateData))
        setNotification({
          type: "success",
          message: "Activity type successfully updated",
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        const { message } = err.response.data
        setNotification({
          type: "error",
          message: "Update Activity Type Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Activity Type Failed",
          description: err.message,
        })
      }
    }
  })
}

export function* deleteCustomerActivityType() {
  yield takeEvery(DELETE_CUSTOMER_ACTIVITY_TYPE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(
        CustomerActivityTypeService.deleteCustomerActivityType,
        payload
      )
      if (group.alert) {
        setNotification({
          type: "error",
          message: "Delete Activity Type Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Activity Type in Activity Type. ActivityCode - ${payload.ActivityCode}`
          )
        )

        const deleteData = {
          type: "DELETE",
          data: { ...payload },
        }
        yield put(getResultCustomerActivityType(deleteData))
        setNotification({
          type: "success",
          message: "Activity type successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Activity Type Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Activity Type Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listCustomerActivityTypesAPI),
    fork(getCustomerActivityType),
    fork(getListCustomerTypeCode),
    fork(createCustomerActivityType),
    fork(updateCustomerActivityType),
    fork(deleteCustomerActivityType),
  ])
}
