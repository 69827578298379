import {
  SET_AUTH_LOADING,
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  UNAUTHENTICATED,
  CUSTOMER_AUTHENTICATED,
  SYSTEM_AUTHENTICATED,
  ROLES,
  PRIVILEGES,
  SET_ROLES_PRIVILEGES,
  LOGOUT,
  SET_LOGIN_ACTIVITIES,
} from "../constants/Auth"

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN),
  systemAuthenticated: false,
  roles: JSON.parse(localStorage.getItem(ROLES)) || [],
  privileges: JSON.parse(localStorage.getItem(PRIVILEGES)) || [],
  loginActivities: {
    count: 0,
    rows: [],
  },
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case SET_AUTH_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case CUSTOMER_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: `/self-service/customer/${action.data.customer_id}/edit`,
        token: action.data.access_token,
        customerId: action.data.customer_id,
      }
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.data.access_token,
        userinfo: action.data.user_info,
      }
    case UNAUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: null,
      }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      }
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
      }
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }
    case SYSTEM_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        systemAuthenticated: true,
      }
    case SET_ROLES_PRIVILEGES:
      return {
        ...state,
        roles: action.payload.roles,
        privileges: action.payload.privileges,
      }
    case SET_LOGIN_ACTIVITIES:
      return {
        ...state,
        loginActivities: action.payload,
        loading: false,
      }
    case LOGOUT:
      return {
        ...state,
        token: null,
        systemAuthenticated: false,
        roles: [],
        privileges: [],
        redirect: "/",
        loading: false,
        loginActivities: {
          count: 0,
          rows: [],
        },
      }
    default:
      return state
  }
}

export default auth
