import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import setNotification from "components/shared-components/Notification"
import {
  CREATE_LEAD_SOURCE_PROSPECTS,
  GET_LIST_LEAD_SOURCE_PROSPECTS,
  GET_LEAD_SOURCE_PROSPECTS_DETAIL,
} from "redux/constants/ImportLeadsProspects"
import ImportLeadsProspectsService from "services/ImportLeadsProspectsService"
import {
  setCreateLeadSourceProspects,
  setListLeadSourceProspects,
  setLeadSourceProspectsDetail,
} from "redux/actions/ImportLeadsProspects"

export function* createLeadSourceProspects() {
  yield takeEvery(CREATE_LEAD_SOURCE_PROSPECTS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      let createdLeadSourceProspect

      const existingLeadSource = yield call(
        ImportLeadsProspectsService.findExistingLeadSource,
        payload
      )

      if (!existingLeadSource.data.rows.length) {
        createdLeadSourceProspect = yield call(
          ImportLeadsProspectsService.createLeadSourceProspect,
          payload
        )

        if (createdLeadSourceProspect.alert) {
          setNotification({
            type: "error",
            message: "Create Lead Source Prospect Failed",
            description: "An error occured",
          })
        } else {
          const newData = {
            CustomerId: createdLeadSourceProspect.data.CustomerId,
            FullName: createdLeadSourceProspect.data.FullName,
            ContactNo: createdLeadSourceProspect.data.ContactNo,
            Email: createdLeadSourceProspect.data.Email || "N/A",
            IdType: createdLeadSourceProspect.data.IdType || "N/A",
            IdNo: createdLeadSourceProspect.data.IdNo || "N/A",
          }

          yield put(
            createLoginActivity(
              `Add Customer Lead Source in Import Leads/Prospect. CustomerId - ${createdLeadSourceProspect.data.CustomerId}`
            )
          )

          yield put(setCreateLeadSourceProspects(newData))
        }
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Create Lead Source Prospect Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getAllLeadSourceProspects() {
  yield takeEvery(GET_LIST_LEAD_SOURCE_PROSPECTS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const customerLeadSource = yield call(
        ImportLeadsProspectsService.getListLeadSourceProspects,
        payload
      )
      if (customerLeadSource.alert) {
        setNotification({
          type: "error",
          message: "Get Lead Source Prospects Failed",
          description: "An error occured",
        })
      } else {
        yield put(setListLeadSourceProspects(customerLeadSource.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Lead Source Prospects Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getOneLeadSourceProspects() {
  yield takeEvery(GET_LEAD_SOURCE_PROSPECTS_DETAIL, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const customerLeadSource = yield call(
        ImportLeadsProspectsService.getOneLeadSourceProspects,
        payload
      )

      if (customerLeadSource.alert) {
        setNotification({
          type: "error",
          message: "Get Lead Source Prospects Failed",
          description: "An error occured",
        })
      } else {
        yield put(setLeadSourceProspectsDetail(customerLeadSource.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Lead Source Prospects Failed",
        description: "An error occured",
      })
    }
  })
}
export default function* rootSaga() {
  yield all([
    fork(createLeadSourceProspects),
    fork(getAllLeadSourceProspects),
    fork(getOneLeadSourceProspects),
  ])
}
