import moment from "moment"
import Fetch from "auth/APIFetchInterceptor"
import CommonService from "./CommonService"
import { AUTH_TOKEN } from "redux/constants/Auth"

const MemberArchiveInactiveMemberServices = {}

MemberArchiveInactiveMemberServices.getListInactiveMembers = function (data) {
  const searchQuery = {}

  if (data?.LastTransactionDateFrom)
    searchQuery.LastTransactionDateFrom = moment(
      data?.LastTransactionDateFrom
    ).format("YYYY-MM-DD")
  if (data?.LastTransactionDateTo)
    searchQuery.LastTransactionDateTo = moment(
      data?.LastTransactionDateTo
    ).format("YYYY-MM-DD")
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.MemberName) searchQuery.MemberName = data.MemberName
  if (data?.MemberIdType) searchQuery.IdType = data.MemberIdType
  if (data?.MemberIdNo) searchQuery.IdNo = data.MemberIdNo
  if (data?.MemberContactNo) searchQuery.ContactNo = data.MemberContactNo
  if (data.rows) searchQuery.Rows = data.rows ?? 999
  if (data.page) searchQuery.Page = data.page ?? 1

  const payload = { ...searchQuery }

  return Fetch({
    url: "/reporting/inactive-member-report",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

MemberArchiveInactiveMemberServices.updateInactiveMember = function (data) {
  const params = {
    Status: "Inactive",
  }

  if (data?.MemberType) {
    params.MemberType = data.MemberType
  }

  return Fetch({
    url: `/customer/customers/status/update`,
    method: "post",
    data: { ...params, CustomerId: data.memberId },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

MemberArchiveInactiveMemberServices.getListMemberIdType = function () {
  return CommonService.getCommonData("ID Type")
}

export default MemberArchiveInactiveMemberServices
