import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_FEEDBACK_OPTION,
  DELETE_FEEDBACK_OPTION,
  GET_LIST_FEEDBACK_OPTION,
  UPDATE_FEEDBACK_OPTION,
} from "redux/constants/FeedbackOption"
import FeedbackOptionService from "services/FeedbackOptionService"
import setNotification from "components/shared-components/Notification"
import {
  setListFeedbackOption,
  setCreateFeedbackOption,
  setUpdateFeedbackOption,
  setDeleteFeedbackOption,
} from "redux/actions/FeedbackOption"

export function* createFeedbackOption() {
  yield takeEvery(CREATE_FEEDBACK_OPTION, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackOption = yield call(
        FeedbackOptionService.createFeedbackOption,
        payload
      )
      if (feedbackOption.alert) {
        setNotification({
          type: "error",
          message: "Create Option Failed",
          description: "An error occured",
        })
      } else {
        yield put(setCreateFeedbackOption(feedbackOption.data))
        setNotification({
          type: "success",
          message: "Create Option Success",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Option Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Option Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* getAllFeedbackOption() {
  yield takeEvery(GET_LIST_FEEDBACK_OPTION, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackOption = yield call(
        FeedbackOptionService.getListFeedbackOption,
        payload
      )
      if (feedbackOption.alert) {
        setNotification({
          type: "error",
          message: "Get Option Failed",
          description: "An error occured",
        })
      } else {
        yield put(setListFeedbackOption(feedbackOption.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Option Failed",
        description: "An error occured",
      })
    }
  })
}

export function* updateFeedbackOption() {
  yield takeEvery(UPDATE_FEEDBACK_OPTION, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackOption = yield call(
        FeedbackOptionService.updateFeedbackOption,
        payload
      )
      if (feedbackOption.alert) {
        setNotification({
          type: "error",
          message: "Update Option failed",
          description: "An error occured",
        })
      } else {
        yield put(setUpdateFeedbackOption(feedbackOption.data))
        setNotification({
          type: "success",
          message: "Option successfully updated",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Update Option Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Option Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* deleteFeedbackOption() {
  yield takeEvery(DELETE_FEEDBACK_OPTION, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const feedbackOption = yield call(
        FeedbackOptionService.deleteFeedbackOption,
        payload
      )
      if (feedbackOption.alert) {
        setNotification({
          type: "error",
          message: "Delete Option failed",
          description: "An error occured",
        })
      } else {
        yield put(setDeleteFeedbackOption(payload))
        setNotification({
          type: "success",
          message: "Option successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Option Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Option Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createFeedbackOption),
    fork(getAllFeedbackOption),
    fork(updateFeedbackOption),
    fork(deleteFeedbackOption),
  ])
}
