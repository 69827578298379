import { Modal } from "antd"
import setNotification from "components/shared-components/Notification"
import { isArray } from "lodash"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { alertCustomer } from "redux/actions/Customer"
import {
  setCustomerProfile,
  setPasscodeError,
  updateCustomer,
} from "redux/actions/SelfService"
import {
  AUTH_TOKEN,
  CLIENT_IP_ADDRESS,
  CUSTOMER_TOKEN,
  UNIQUE_SESSION_ID,
} from "redux/constants/Auth"
import {
  UPDATE_CUSTOMER_SELF_SERVICE,
  GET_CUSTOMER_PROFILE,
  SET_CUSTOMER_PROFILE,
  GET_LIST_PASSCODE,
  VALIDATE_PASSCODE,
  GENERATE_PASSCODE,
  GET_CARD_LEVEL_DETAIL_SELF_SERVICE,
  VERIFY_REFERRER_SELF_SERVICE,
} from "redux/constants/SelfService"
import AuthService from "services/AuthService"
import SelfService from "services/SelfService"

export function* updateCustomerAPISelfServiceAPI() {
  yield takeEvery(UPDATE_CUSTOMER_SELF_SERVICE, function* ({ payload }) {
    try {
      const { data } = yield call(SelfService.updateCustomerSelfService, {
        data: payload.data,
      })
      if (data.alert) {
        yield put(alertCustomer({ success: false, message: data.alert }))
      } else {
        yield put(
          alertCustomer({
            success: true,
            message: "Customer details have been updated successfully.",
          })
        )
        localStorage.removeItem(CUSTOMER_TOKEN)
        sessionStorage.removeItem(CUSTOMER_TOKEN)
        yield put(updateCustomer(data))
      }
    } catch (err) {
      yield put(
        alertCustomer({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* getCustomerProfileAPI() {
  yield takeEvery(GET_CUSTOMER_PROFILE, function* ({ payload }) {
    try {
      const { data } = yield call(SelfService.getCustomerProfile)
      if (data.alert) {
        yield put(alertCustomer({ success: false, message: data.alert }))
      } else {
        yield put(setCustomerProfile(data))
        yield put(
          alertCustomer({
            success: true,
            message: "Data retrieved successfully",
          })
        )
      }
    } catch (err) {
      yield put(
        alertCustomer({
          success: false,
          message: err.response.data?.message,
        })
      )
    }
  })
}

export function* setCustomerProfileAPI() {
  yield takeEvery(SET_CUSTOMER_PROFILE, function* ({ payload }) {
    yield put(setCustomerProfile(payload))
  })
}

export function* getAllPasscodeAPI() {
  yield takeEvery(GET_LIST_PASSCODE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const passcode = yield call(SelfService.getListPasscode, payload)

      if (!passcode.data.count) {
        Modal.error({
          title: "Invalid code",
          content: "Please refer to the sales person",
          okButtonProps: {
            style: { display: "none" },
          },
        })
        return
      } else {
        if (passcode.data?.rows?.[0]?.Status === "Expired") {
          Modal.error({
            title: "Expired code",
            content: "Please refer to the sales person",
            okButtonProps: {
              style: { display: "none" },
            },
          })
          return
        } else if (passcode.data?.rows?.[0]?.Status === "Completed") {
          Modal.error({
            title: "Invalid code",
            content: "This passcode has been used",
            okButtonProps: {
              style: { display: "none" },
            },
          })
          return
        }
      }

      yield put(
        setPasscodeError({
          isFetched: true,
          isError: false,
        })
      )
    } catch (err) {
      setNotification({
        type: "error",
        message: "Get Passcode Failed",
        description: "An error occured",
      })

      yield put(
        setPasscodeError({
          isFetched: true,
          isError: true,
        })
      )
    }
  })
}

export function* validatePasscodeAPI() {
  yield takeEvery(VALIDATE_PASSCODE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      yield call(SelfService.validatePasscode, payload)

      yield put(
        setPasscodeError({
          isFetched: true,
          isError: false,
        })
      )
    } catch (err) {
      if (Object.keys(err?.response?.data).length) {
        Modal.error({
          title: `${err.response.data.message.includes("Expired")
            ? "Expired"
            : "Invalid"
            } code`,
          content: "Please refer to the sales person",
          okButtonProps: {
            style: { display: "none" },
          },
        })

        yield put(
          setPasscodeError({
            isFetched: true,
            isError: true,
          })
        )
      }
    }
  })
}

export function* generatePasscodeAPI() {
  yield takeEvery(GENERATE_PASSCODE, function* ({ payload }) {
    const sessionId = localStorage.getItem(UNIQUE_SESSION_ID)
    const userIP = localStorage.getItem(CLIENT_IP_ADDRESS)
    let auth_token = localStorage.getItem(AUTH_TOKEN)
    let userInfo = JSON.parse(localStorage.getItem("userinfo"))

    payload.access_token = auth_token

    try {
      const response = yield call(SelfService.generatePasscode, payload)

      /** Add Login Activity */
      yield call(AuthService.createLoginActivity, {
        SessionId: sessionId,
        SourceIP: userIP,
        ObjectType: "UserId",
        ObjectId: String(userInfo?.id),
        ActivityDateTime: new Date(),
        ActivityDescription: "Generate QR Code in Self-Service URL Generator.",
      })

      yield call(payload.callback, response.data?.Passcode)
    } catch (err) {
      setNotification({
        type: "error",
        message: "Generate Passcode failed",
      })
    }
  })
}

export function* getCardLevelDetailSelfServiceAPI() {
  yield takeEvery(GET_CARD_LEVEL_DETAIL_SELF_SERVICE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const response = yield call(SelfService.getCardLevelDetail, payload)

      if (!response.data.count) {
        return setNotification({
          type: "error",
          message: "Failed to get card level detail",
        })
      }

      const cardLevelDetail = response.data.rows[0]

      if (cardLevelDetail) {
        payload.callback(cardLevelDetail)
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: "Failed to get card level detail",
      })
    }
  })
}

export function* verifyReferrerSelfServiceAPI() {
  yield takeEvery(VERIFY_REFERRER_SELF_SERVICE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token

    try {
      const response = yield call(SelfService.verifyReferrer, payload)

      if (!response.data.count) {
        return setNotification({
          type: "error",
          message: "Referrer has no purchase",
        })
      }

      // When there is a purchase by referrer, it will search for memberships and assign them as referrer
      yield call(payload.callback)
    } catch (err) {
      setNotification({
        type: "error",
        message: "Verify Referrer Membership No failed",
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(updateCustomerAPISelfServiceAPI),
    fork(getCustomerProfileAPI),
    fork(getAllPasscodeAPI),
    fork(validatePasscodeAPI),
    fork(generatePasscodeAPI),
    fork(getCardLevelDetailSelfServiceAPI),
    fork(verifyReferrerSelfServiceAPI),
  ])
}
