import React, { lazy, Suspense, useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Loading from "components/shared-components/Loading"
import { APP_PREFIX_PATH } from "configs/AppConfig"
import Dashboard from "./dashboard"
import { useDispatch, useSelector } from "react-redux"
import { getRolesPrivileges } from "redux/actions/Auth"
import Utils from "utils"

export const AppViews = () => {
  const dispatch = useDispatch()
  const { roles, privileges } = useSelector((state) => state.auth)
  useEffect(() => {
    if (roles.length < 1) {
      dispatch(getRolesPrivileges())
    }
  }, [roles, privileges, dispatch])
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`views/app-views/settings`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/setup`}
          component={lazy(() => import(`views/app-views/setup`))}
        />
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={Dashboard} />
        {Utils.checkUserRole("Customer") && (
          <Route
            path={`${APP_PREFIX_PATH}/registration`}
            component={lazy(() => import(`views/app-views/registration`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/points-management`}
          component={lazy(() => import(`views/app-views/points-management`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/member`}
          component={lazy(() => import(`views/app-views/member`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/rewards`}
          component={lazy(() => import(`views/app-views/rewards`))}
        />
        {Utils.checkUserRole("Campaign") && (
          <Route
            path={`${APP_PREFIX_PATH}/campaign`}
            component={lazy(() => import(`views/app-views/campaign`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/reporting`}
          component={lazy(() => import(`views/app-views/reporting`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/feedback`}
          component={lazy(() => import(`views/app-views/feedback`))}
        />

        {Utils.checkUserRole("Member Listing") && (
          <Route
            path={`${APP_PREFIX_PATH}/member-list/view`}
            component={lazy(() =>
              import(`views/app-views/reporting/member-listing/view`)
            )}
          />
        )}
        {Utils.checkUserRole("Customer Reward Status Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/customer-reward-status-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/customer-reward-status-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Customer Performance Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/customer-performance-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/customer-performance-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Member Profile Amendment Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/member-profile-amendment-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/member-profile-amendment-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Member Point Statement") && (
          <Route
            path={`${APP_PREFIX_PATH}/member-point-statement/view`}
            component={lazy(() =>
              import(`views/app-views/reporting/member-point-statement/view`)
            )}
          />
        )}
        {Utils.checkUserRole("Member Point Statement") && (
          <Route
            path={`${APP_PREFIX_PATH}/member-point-statement/view-summary`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/member-point-statement/view-summary`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Customer Analysis Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/customer-analysis-report/view`}
            component={lazy(() =>
              import(`views/app-views/reporting/customer-analysis-report/view`)
            )}
          />
        )}
        {Utils.checkUserRole("Product Sales Analysis Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/product-sales-analysis-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/product-sales-analysis-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Member Sales Analysis Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/member-sales-analysis-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/member-sales-analysis-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Customer Transaction Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/customer-transaction-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/customer-transaction-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Non-Member Transaction Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/non-member-transaction-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/non-member-transaction-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Repeat Customer Analysis Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/repeat-customer-analysis-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/repeat-customer-analysis-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Point Expiry Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/point-expiry-report/view`}
            component={lazy(() =>
              import(`views/app-views/reporting/point-expiry-report/view`)
            )}
          />
        )}
        {Utils.checkUserRole("Premium Gift Redemption Listing") && (
          <Route
            path={`${APP_PREFIX_PATH}/premium-gift-redemption-listing/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/premium-gift-redemption-listing/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Inactive/Reactive Member Listing") && (
          <Route
            path={`${APP_PREFIX_PATH}/inactive-reactive-member-listing/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/inactive-reactive-member-listing/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Member Category Analysis Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/member-category-analysis-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/member-category-analysis-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Audit Trail Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/audit-trail-report/view`}
            component={lazy(() =>
              import(`views/app-views/reporting/audit-trail-report/view`)
            )}
          />
        )}
        {Utils.checkUserRole("Feedback Analysis Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/feedback-analysis-report/view`}
            component={lazy(() =>
              import(`views/app-views/reporting/feedback-analysis-report/view`)
            )}
          />
        )}
        {Utils.checkUserRole("Campaign Target List Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/campaign-target-list-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/campaign-target-list-report/view`
              )
            )}
          />
        )}
        {Utils.checkUserRole("Friend-Get-Friend Sales Report") && (
          <Route
            path={`${APP_PREFIX_PATH}/friend-get-friend-sales-report/view`}
            component={lazy(() =>
              import(
                `views/app-views/reporting/friend-get-friend-sales-report/view`
              )
            )}
          />
        )}

        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
