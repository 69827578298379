import {
  CREATE_PROFILE_UPDATED_HISTORY,
  SET_PROFILE_UPDATED_HISTORY_LOADING,
  GET_PROFILE_UPDATED_HISTORY_LIST,
  SET_PROFILE_UPDATED_HISTORY_LIST,
  UPDATE_PROFILE_UPDATED_HISTORY,
  SET_UPDATE_PROFILE_UPDATED_HISTORY,
  CLEAR_PROFILE_UPDATED_HISTORY_LIST,
} from "redux/constants/ProfileUpdatedHistory"

export const createProfileUpdatedHistory = (data) => {
  return {
    type: CREATE_PROFILE_UPDATED_HISTORY,
    payload: data,
  }
}

export const setProfileUpdatedHistoryLoading = (data) => {
  return {
    type: SET_PROFILE_UPDATED_HISTORY_LOADING,
    payload: data,
  }
}

export const getProfileUpdatedHistoryList = (data) => {
  return {
    type: GET_PROFILE_UPDATED_HISTORY_LIST,
    payload: data,
  }
}

export const setProfileUpdatedHistoryList = (data) => {
  return {
    type: SET_PROFILE_UPDATED_HISTORY_LIST,
    payload: data,
  }
}

export const updateProfileUpdatedHistory = (data) => {
  return {
    type: UPDATE_PROFILE_UPDATED_HISTORY,
    payload: data,
  }
}

export const setUpdateProfileUpdatedHistory = (data) => {
  return {
    type: SET_UPDATE_PROFILE_UPDATED_HISTORY,
    payload: data,
  }
}

export const clearProfileUpdatedHistoryList = () => {
  return {
    type: CLEAR_PROFILE_UPDATED_HISTORY_LIST,
  }
}
