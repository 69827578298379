import {
  GET_REWARD,
  GET_POINT_ADJUSTMENTS_CANCEL_REDEEM_REWARD,
  SET_IS_CANCELLED,
  SET_IS_LOADING,
  ON_CANCEL_REDEEMED_REWARD,
  SET_REWARD,
  SET_POINT_ADJUSTMENTS_CANCEL_REDEEM_REWARD,
  SET_REWARD_IMAGES,
  RESET_CANCEL_REDEEM_REWARD,
} from "redux/constants/CancelRedeemReward"

export const getReward = (data) => {
  return {
    type: GET_REWARD,
    payload: data,
  }
}

export const setReward = (data) => {
  return {
    type: SET_REWARD,
    payload: data,
  }
}

export const getPointAdjustmentCancelRedeemReward = (data) => {
  return {
    type: GET_POINT_ADJUSTMENTS_CANCEL_REDEEM_REWARD,
    payload: data,
  }
}

export const setPointAdjustmentCancelRedeemReward = (data) => {
  return {
    type: SET_POINT_ADJUSTMENTS_CANCEL_REDEEM_REWARD,
    payload: data,
  }
}

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export const setIsCancelled = (isCancelled) => {
  return {
    type: SET_IS_CANCELLED,
    payload: isCancelled,
  }
}

export const setRewardImages = (data) => {
  return {
    type: SET_REWARD_IMAGES,
    payload: data,
  }
}

export const onCancelReward = (data) => {
  return {
    type: ON_CANCEL_REDEEMED_REWARD,
    payload: data,
  }
}

export const reset = () => {
  return {
    type: RESET_CANCEL_REDEEM_REWARD,
    payload: null,
  }
}
