import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_TASK_GROUP,
  GET_LIST_TASK_GROUP,
  GET_TASK_GROUP,
  UPDATE_TASK_GROUP,
  DELETE_TASK_GROUP,
} from "../constants/TaskGroup"
import { resultTaskGroup, alertTaskGroup } from "../actions/TaskGroup"
import TaskGroupService from "services/TaskGroupService"
import { isArray } from "lodash"
import { createLoginActivity } from "redux/actions/Auth"

//generator
export function* createTaskGroupAPI() {
  yield takeEvery(CREATE_TASK_GROUP, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(TaskGroupService.createTaskGroup, payload)
      if (group.alert) {
        yield put(alertTaskGroup({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Add Task Group in Task Group. Code - ${group.data.Code}`
          )
        )

        group.type = "CREATE"
        yield put(resultTaskGroup(group))
        yield put(
          alertTaskGroup({
            success: true,
            message: "A new task group has been created",
          })
        )
      }
    } catch (err) {
      yield put(
        alertTaskGroup({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* listTaskGroupAPI() {
  yield takeEvery(GET_LIST_TASK_GROUP, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(TaskGroupService.getListTaskGroup, payload)
      if (group.alert) {
      } else {
        group.type = "LIST"
        yield put(resultTaskGroup(group))
      }
    } catch (err) {}
  })
}

export function* getTaskGroupAPI() {
  yield takeEvery(GET_TASK_GROUP, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(TaskGroupService.getTaskGroup, payload)
      if (group.alert) {
        yield put(alertTaskGroup(group.alert))
      } else {
        yield put(resultTaskGroup(group.data))
      }
    } catch (err) {
      yield put(alertTaskGroup(err))
    }
  })
}

export function* updateTaskGroupAPI() {
  yield takeEvery(UPDATE_TASK_GROUP, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(TaskGroupService.updateTaskGroup, payload)
      if (group.alert) {
        yield put(alertTaskGroup({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Update Task Group in Task Group. Code - ${group.data.Code}`
          )
        )

        group.type = "UPDATE"
        group.data = payload
        yield put(resultTaskGroup(group))
        yield put(
          alertTaskGroup({
            success: true,
            message: "Group successfully updated",
          })
        )
      }
    } catch (err) {
      yield put(
        alertTaskGroup({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* deleteTaskGroupAPI() {
  yield takeEvery(DELETE_TASK_GROUP, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(TaskGroupService.deleteTaskGroup, payload)
      if (group.alert) {
        yield put(alertTaskGroup({ success: false, message: group.alert }))
      } else {
        yield put(
          createLoginActivity(
            `Delete Task Group in Task Group. Code - ${payload.Code}`
          )
        )

        group.type = "DELETE"
        group.data = payload
        yield put(resultTaskGroup(group))
        yield put(
          alertTaskGroup({
            success: true,
            message: "Task Group has been deleted/inactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(alertTaskGroup({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listTaskGroupAPI),
    fork(createTaskGroupAPI),
    fork(getTaskGroupAPI),
    fork(updateTaskGroupAPI),
    fork(deleteTaskGroupAPI),
  ])
}
