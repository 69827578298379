import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"
import { isEmpty } from "lodash"

const PointRateService = {}

PointRateService.listPointRates = function (data) {
  return fetch({
    url: `/point/rates/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: data.rows ? data.rows : 20,
      Pages: data.page ? data.page : 1,
      Search: JSON.stringify(data.queries ? data.queries : {}),
    },
  })
}

PointRateService.deletePointRate = function (id) {
  return fetch({
    url: `/point/rates/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { RateId: id },
  })
}

PointRateService.createPointRate = async function (data) {
  const isAllBrand = data.pointRate.Brand === "All"
  const brands = data.brands

  if (isAllBrand) {
    let pointRate = null

    for (let i = 0; i < brands.length; i++) {
      const Brand = brands[i].Name

      const pointRatePayload = {
        ...data.pointRate,
        Brand,
        ObjectType: "ProntoStockGroup",
      }
      const pointRateDetailsPayload = data.pointRateDetails

      pointRate = await fetch({
        url: `/point/rates/add`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
        data: pointRatePayload,
      })

      if (!isEmpty(pointRateDetailsPayload))
        await fetch({
          url: `/point/rate-details/add`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          },
          data: { ...pointRateDetailsPayload, RateId: pointRate.data.RateId },
        })
    }

    return pointRate
  }

  const pointRatePayload = data.pointRate
  const pointRateDetailsPayload = data.pointRateDetails
  const RateId = sessionStorage.getItem("RateId")

  let pointRate = null
  if (RateId && RateId !== "undefined") {
    pointRate = await fetch({
      url: `/point/rates/update`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
      },
      data: { ...pointRatePayload, RateId },
    })
  } else {
    pointRate = await fetch({
      url: `/point/rates/add`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
      },
      data: pointRatePayload,
    })
    sessionStorage.setItem("RateId", pointRate.data.RateId)
  }

  const pointRateDetails = await fetch({
    url: `/point/rate-details/add`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...pointRateDetailsPayload, RateId: pointRate.data.RateId },
  })

  if (pointRateDetails) {
    sessionStorage.removeItem("RateId")
  }

  return pointRate
}

PointRateService.updatePointRate = function (data) {
  return fetch({
    url: `/point/rates/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

PointRateService.getPointRate = function (data) {
  return fetch({
    url: `/point/rates/find-one`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { RateId: data.RateId },
  })
}

PointRateService.createPointRateDetail = function (data) {
  return fetch({
    url: `/point/rate-details/add`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

PointRateService.listPointRateDetailsByRateStructure = function (data) {
  return fetch({
    url: `/point/rates/${data.RateId}/details`,
    method: "GET",
    params: {
      rows: data.rows ? data.rows : 20,
      page: data.page ? data.page : 1,
      search: data.queries ? data.queries : {},
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

PointRateService.listPointRateDetails = function (data) {
  return fetch({
    url: `/point/rate-details/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: data.rows ? data.rows : 20,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(data.queries ? data.queries : {}),
    },
  })
}

PointRateService.getPointRateDetail = function (data) {
  return fetch({
    url: `/point/rate-details/find-one`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      RateDetailId: data.RateDetailId,
    },
  })
}

PointRateService.expirePointRateDetail = function (data) {
  return fetch({
    url: `/point/rate-details/expire`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...data, RateDetailId: data.RateDetailId },
  })
}

PointRateService.updatePointRateDetail = function (data) {
  return fetch({
    url: `/point/rate-details/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      ...data,
      Amount: parseFloat(data.Amount).toFixed(2),
      PointRate: parseFloat(data.PointRate).toFixed(2),
    },
  })
}

PointRateService.deletePointRateDetail = function (data) {
  return fetch({
    url: `/point/rate-details/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { RateDetailId: data.RateDetailId },
  })
}

export default PointRateService
