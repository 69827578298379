import { all, fork, takeEvery, call, put } from "@redux-saga/core/effects"
import setNotification from "../../components/shared-components/Notification"
import {
  CREATE_PROFILE_UPDATED_HISTORY,
  GET_PROFILE_UPDATED_HISTORY_LIST,
  UPDATE_PROFILE_UPDATED_HISTORY,
} from "redux/constants/ProfileUpdatedHistory"
import {
  setProfileUpdatedHistoryLoading,
  setProfileUpdatedHistoryList,
  setUpdateProfileUpdatedHistory,
} from "redux/actions/ProfileUpdatedHistory"
import ProfileUpdatedHistoryService from "services/ProfileUpdatedHistoryService"

export function* createProfileUpdatedHistoryAPI() {
  yield takeEvery(CREATE_PROFILE_UPDATED_HISTORY, function* ({ payload }) {
    try {
      yield put(setProfileUpdatedHistoryLoading(true))
      yield call(
        ProfileUpdatedHistoryService.createProfileUpdatedHistory,
        payload
      )
      // if (histories) {
      //   yield put(setProfileUpdatedHistoryList(histories))
      // }
    } catch (err) {
      yield put(setProfileUpdatedHistoryLoading(false))
      setNotification({
        type: "error",
        message: "Create Profile Updated History Failed",
        description: "An error occured.",
      })
    }
  })
}

export function* updateProfileUpdatedHistoryAPI() {
  yield takeEvery(UPDATE_PROFILE_UPDATED_HISTORY, function* ({ payload }) {
    let userInfo = JSON.parse(localStorage.getItem("userinfo"))

    try {
      yield put(setProfileUpdatedHistoryLoading(true))
      if (payload.HistoryIds.length) {
        for (const historyId of payload.HistoryIds) {
          yield call(ProfileUpdatedHistoryService.updateProfileUpdatedHistory, {
            HistoryId: historyId,
            ReviewedById: String(userInfo.id),
            ReviewedDate: new Date(),
          })
        }

        if (payload.callback) {
          payload.callback()
          setNotification({
            type: "success",
            message: "Review Profile Updated History success",
          })
        }
      } else {
        yield call(
          ProfileUpdatedHistoryService.updateProfileUpdatedHistory,
          payload
        )
      }

      yield put(setProfileUpdatedHistoryLoading(false))
      yield put(setUpdateProfileUpdatedHistory())
    } catch (err) {
      yield put(setProfileUpdatedHistoryLoading(false))
      setNotification({
        type: "error",
        message: "Update Profile Updated History Failed",
        description: "An error occured.",
      })
    }
  })
}

export function* listProfileUpdatedHistoriesAPI() {
  yield takeEvery(GET_PROFILE_UPDATED_HISTORY_LIST, function* ({ payload }) {
    try {
      yield put(setProfileUpdatedHistoryLoading(true))
      const histories = yield call(
        ProfileUpdatedHistoryService.listMemberProfileAmendment,
        payload
      )
      if (histories) {
        yield put(setProfileUpdatedHistoryList(histories))
      }
    } catch (err) {
      yield put(setProfileUpdatedHistoryLoading(false))
      setNotification({
        type: "error",
        message: "List Failed",
        description: "Profile updated histories failed to be listed.",
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listProfileUpdatedHistoriesAPI),
    fork(createProfileUpdatedHistoryAPI),
    fork(updateProfileUpdatedHistoryAPI),
  ])
}
