import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { CREATE_AUDIT_LOG, GET_LIST_AUDIT_LOG } from "redux/constants/AuditLog"
import setNotification from "components/shared-components/Notification"
import AuditLogService from "services/AuditLogService"
import { setListAuditLog, setCreateAuditLog } from "redux/actions/AuditLog"

export function* createAuditLogAPI() {
  yield takeEvery(CREATE_AUDIT_LOG, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const auditLog = yield call(AuditLogService.createAuditLog, payload)
      if (auditLog.alert) {
        setNotification({
          type: "error",
          message: "Create Audit Log Failed",
          description: "An error occured",
        })
      } else {
        yield put(setCreateAuditLog(auditLog.data))
        // setNotification({
        //   type: "success",
        //   message: "Create Audit Log Success",
        // })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Audit Log Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Audit Log Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* getAllAuditLogAPI() {
  yield takeEvery(GET_LIST_AUDIT_LOG, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const auditLog = yield call(AuditLogService.getListAuditLog, payload)
      if (auditLog.alert) {
        setNotification({
          type: "error",
          message: "Get Audit Log Failed",
          description: "An error occured",
        })
      } else {
        yield put(setListAuditLog(auditLog.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Audit Log Failed",
        description: "An error occured",
      })
    }
  })
}

export default function* rootSaga() {
  yield all([fork(createAuditLogAPI), fork(getAllAuditLogAPI)])
}
