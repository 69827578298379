import moment from "moment"
import axios from "axios"
import { COMMON_API_BASE_URL } from "configs/AppConfig"
import { CUSTOMER_TOKEN, AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const SelfService = {}

const commonFetch = axios.create({
  baseURL: COMMON_API_BASE_URL,
  timeout: 60000,
})

SelfService.createCustomerSelfService = function ({ data }) {
  return fetch({
    url: "/customer/customers/self-service",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CUSTOMER_TOKEN)}`,
    },
    data,
  })
}

SelfService.updateCustomerSelfService = function ({ data }) {
  return fetch({
    url: `/customer/profile`,
    method: "put",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CUSTOMER_TOKEN)}`,
    },
    data,
  })
}

SelfService.getCustomerProfile = function () {
  return fetch({
    url: `/customer/profile`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CUSTOMER_TOKEN)}`,
    },
  })
}

SelfService.getListPasscode = function (data) {
  const searchQuery = {}
  if (!(data.Passcode === "")) {
    searchQuery.Passcode = data.Passcode
  }
  if (!(data.ObjectType === "")) {
    searchQuery.ObjectType = data.ObjectType
  }
  if (!(data.ObjectId === "")) {
    searchQuery.ObjectId = data.ObjectId
  }

  return commonFetch({
    url: "/passcodes/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

SelfService.validatePasscode = function (data) {
  return commonFetch({
    url: "/passcodes/validate",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data,
  })
}

SelfService.generatePasscode = function (data) {
  return commonFetch({
    url: "/passcodes/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data,
  })
}

SelfService.getCardLevelDetail = function (data) {
  const searchQuery = { Status: "Active" }

  if (data.FirstSalesAmount) {
    searchQuery.FirstSalesAmount = parseFloat(data.FirstSalesAmount)
  }

  const payload = {
    Search: JSON.stringify(searchQuery),
  }

  return fetch({
    url: `/member/card-levels/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CUSTOMER_TOKEN)}`,
    },
    data: payload,
  })
}

SelfService.verifyReferrer = function (data) {
  const payload = {}

  payload.TransactionDateFrom = moment("2020-01-01").format("YYYY-MM-DD")
  payload.TransactionDateTo = moment().format("YYYY-MM-DD")

  payload.AmountFrom = 1
  payload.AmountTo = 9999999
  payload.ExcludeCancelTrxYN = "Y"

  if (data?.MembershipNo) payload.MembershipNo = data.MembershipNo

  return fetch({
    url: `/reporting/customer-transaction-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

export default SelfService
