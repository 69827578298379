import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const RewardInventoryService = {}

RewardInventoryService.createRewardInventory = function (data) {
  return fetch({
    url: "/reward/inventories/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

RewardInventoryService.updateRewardInventory = function (data) {
  return fetch({
    url: `/reward/inventories/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

RewardInventoryService.deleteRewardInventory = function ({ InventoryId }) {
  return fetch({
    url: `/reward/inventories/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { InventoryId },
  })
}

export default RewardInventoryService
