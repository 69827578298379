import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const SettingService = {}

SettingService.listStockGroups = function (params) {
  return fetch({
    url: `/settings/stock-groups`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    params,
  })
}

SettingService.getSettings = function (name) {
  let searchquery = {}
  if (name) {
    searchquery.Name = name
  }
  return fetch({
    url: `/settings/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Search: JSON.stringify(searchquery ?? {}),
    },
  })
}

export default SettingService
