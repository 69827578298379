import React, { useState } from "react"
import { Menu, Dropdown, Avatar } from "antd"
import { EditOutlined, LogoutOutlined } from "@ant-design/icons"
import Icon from "components/util-components/Icon"
import { UserOutlined } from "@ant-design/icons"
import ChangePasswordModal from "./ChangePasswordModal"
import { useHistory } from "react-router-dom"

const NavProfile = () => {
  const history = useHistory()
  const userInfo = JSON.parse(localStorage.getItem("userinfo"))
  const [showModal, setShowModal] = useState(false)

  const menuItem = [
    {
      title: "Change Password",
      icon: EditOutlined,
      action: () => setShowModal(true),
    },
  ]

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {userInfo.staff.image ? (
            <Avatar size={45} src={userInfo.staff.image} />
          ) : (
            <Avatar size={45} icon={<UserOutlined />} />
          )}
          <div className="pl-3">
            <h4 className="mb-0">{userInfo.staff.full_name}</h4>
            <span className="text-muted">{userInfo.staff.job_title}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} onClick={el.action}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            )
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={() => history.push("/logout")}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Logout</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item>
            {userInfo.staff.image ? (
              <Avatar src={userInfo.staff.image} />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
          </Menu.Item>
        </Menu>
      </Dropdown>
      <ChangePasswordModal visible={showModal} setVisible={setShowModal} />
    </>
  )
}

export default NavProfile
