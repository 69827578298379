import { RESULT_SSO_DATA } from "../constants/SSOData"

const initialState = {
  outLetList: [],
  ssoBuilding: [],
  staffList: [],
  ssoDepartment: [],
}

const ssodata = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_SSO_DATA:
      const isOutletList = action.payload.type === "outLetList"
      const isStaffList = action.payload.type === "staffList"
      const isStateList = action.payload.type === "stateList"
      const isCountryList = action.payload.type === "countryList"

      const isFromCommonApi =
        isOutletList || isStaffList || isStateList || isCountryList

      const valueDecider = () => {
        if (isFromCommonApi) {
          return action.payload.data.rows ?? action.payload.data
        }
        return action.payload.data.data
      }

      const value = valueDecider()

      return { ...state, [action.payload.type]: value }

    default:
      return state
  }
}

export default ssodata
