import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  CREATE_TASK_PROGRESS,
  GET_LIST_TASK_PROGRESS,
  GET_TASK_PROGRESS,
  UPDATE_TASK_PROGRESS,
  DELETE_TASK_PROGRESS,
} from "../constants/TaskProgress"
import {
  setTaskProgressLoading,
  resultTaskProgress,
  alertTaskProgress,
} from "../actions/TaskProgress"
import TaskProgressService from "services/TaskProgressService"
import { isArray } from "lodash"
import { AUTH_TOKEN } from "redux/constants/Auth"

export function* createTaskProgressAPI() {
  yield takeEvery(
    CREATE_TASK_PROGRESS,
    function* ({ payload, shouldUpdateList }) {
      let auth_token = localStorage.getItem(AUTH_TOKEN)
      payload.access_token = auth_token
      try {
        const group = yield call(
          TaskProgressService.createTaskProgress,
          payload
        )
        if (group.alert) {
          yield put(alertTaskProgress({ success: false, message: group.alert }))
        } else {
          group.type = shouldUpdateList ? "CREATE" : "CREATE_ONLY"
          yield put(resultTaskProgress(group))

          /** Add Login Activity */
          if (payload.isDashboardTask) {
            yield put(createLoginActivity("Save Progress Log in Dashboard"))
          } else {
            yield put(
              createLoginActivity(
                `Add Progress Log in Customer Task. ProgressId - ${group.data.ProgressLogId}`
              )
            )
          }

          yield put(
            alertTaskProgress({
              success: true,
              message: "A new Progress log has been created",
            })
          )
        }
      } catch (err) {
        yield put(
          alertTaskProgress({
            success: false,
            message: isArray(err.response.data?.message)
              ? err.response.data?.message[0]
              : err.response.data.message,
          })
        )
      }
    }
  )
}

export function* listTaskProgressAPI() {
  yield takeEvery(GET_LIST_TASK_PROGRESS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      yield put(setTaskProgressLoading(true))
      const group = yield call(TaskProgressService.getListTaskProgress, payload)
      if (group.alert) {
        // do nothing
      } else {
        group.type = "LIST"
        yield put(resultTaskProgress(group))
      }
    } catch (err) {
      yield put(setTaskProgressLoading(false))
    }
  })
}

export function* getTaskProgressAPI() {
  yield takeEvery(GET_TASK_PROGRESS, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const group = yield call(TaskProgressService.getTaskProgress, payload)
      if (group.alert) {
        yield put(alertTaskProgress(group.alert))
      } else {
        yield put(resultTaskProgress(group.data))
      }
    } catch (err) {
      yield put(alertTaskProgress(err))
    }
  })
}

export function* updateTaskProgressAPI() {
  yield takeEvery(
    UPDATE_TASK_PROGRESS,
    function* ({ payload, shouldUpdateList }) {
      let auth_token = localStorage.getItem(AUTH_TOKEN)
      payload.access_token = auth_token
      try {
        const group = yield call(
          TaskProgressService.updateTaskProgress,
          payload
        )
        if (group.alert) {
          yield put(alertTaskProgress({ success: false, message: group.alert }))
        } else {
          group.type = shouldUpdateList ? "UPDATE" : "UPDATE_ONLY"
          group.data = payload
          yield put(resultTaskProgress(group))

          /** Add Login Activity */
          yield put(
            createLoginActivity(
              `Update Progress Log in Customer Task. ProgressId - ${payload.ProgressLogId}`
            )
          )

          yield put(
            alertTaskProgress({
              success: true,
              message: "Progress log successfully updated",
            })
          )
        }
      } catch (err) {
        yield put(
          alertTaskProgress({
            success: false,
            message: isArray(err.response.data?.message)
              ? err.response.data?.message[0]
              : err.response.data.message,
          })
        )
      }
    }
  )
}

export function* deleteTaskProgressAPI() {
  yield takeEvery(DELETE_TASK_PROGRESS, function* ({ payload }) {
    let auth_token = localStorage.getItem(AUTH_TOKEN)
    payload.access_token = auth_token
    try {
      const group = yield call(TaskProgressService.deleteTaskProgress, payload)
      if (group.alert) {
        yield put(alertTaskProgress({ success: false, message: group.alert }))
      } else {
        group.type = "DELETE"
        group.data = payload
        yield put(resultTaskProgress(group))

        /** Add Login Activity */
        yield put(
          createLoginActivity(
            `Delete Progress Log in Customer Task. ProgressId - ${payload.ProgressLogId}`
          )
        )

        yield put(
          alertTaskProgress({
            success: true,
            message: "Progress log has been deleted/inactivated successfully",
          })
        )
      }
    } catch (err) {
      yield put(alertTaskProgress({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listTaskProgressAPI),
    fork(createTaskProgressAPI),
    fork(getTaskProgressAPI),
    fork(updateTaskProgressAPI),
    fork(deleteTaskProgressAPI),
  ])
}
