import setNotification from "components/shared-components/Notification"
import { env } from "configs/EnvironmentConfig"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setCrmCustomFieldList,
  setCustomFieldList,
} from "redux/actions/CustomField"
import { COMMON_TOKEN } from "redux/constants/Auth"
import {
  DELETE_COMMON_CUSTOM_FIELD,
  GET_CRM_CUSTOM_FIELD_LIST,
  GET_CUSTOM_FIELD_LIST,
  INSERT_COMMON_CUSTOM_FIELD,
  INSERT_CRM_CUSTOM_FIELD,
  UPDATE_COMMON_CUSTOM_FIELD,
  UPDATE_CRM_CUSTOM_FIELD,
} from "redux/constants/CustomField"
import AuthService from "services/AuthService"
import CustomFieldService from "services/CustomFieldService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* listCustomFieldAPI() {
  yield takeEvery(GET_CUSTOM_FIELD_LIST, function* ({ payload }) {
    try {
      const { data } = yield call(
        CustomFieldService.getCustomFieldList,
        payload
      )

      const rows = []

      for (let i = 0; i < data.rows.length; i++) {
        const field = data.rows[i]
        if (
          field.FieldType === "Selection" ||
          field.FieldType === "Multiple Selection"
        ) {
          const { data: ListItems } = yield call(
            CustomFieldService.getListItems,
            field.FieldListId
          )
          rows.push({ ...field, ListItems: ListItems.rows })
        } else {
          rows.push({ ...field, ListItems: [] })
        }
      }

      data.rows = rows

      yield put(setCustomFieldList(data))
    } catch (err) {
      setError("Listing Failed", err.response.data.message ?? "")
    }
  })
}

export function* addCommonCustomFieldAPI() {
  yield takeEvery(INSERT_COMMON_CUSTOM_FIELD, function* ({ payload }) {
    let common_token = localStorage.getItem(COMMON_TOKEN)
    payload.access_token = common_token

    try {
      const customField = yield call(
        CustomFieldService.addCommonCustomField,
        payload
      )

      yield put(
        createLoginActivity(
          `Add Custom Field in Custom Field. CustomFieldCode - ${customField.data.CustomFieldCode}`
        )
      )

      setNotification({
        type: "success",
        message: "Created custom field.",
      })
    } catch (err) {
      setError("Add Custom Field Failed", err.response.data.message ?? "")
    }
  })
}

export function* updateCommonCustomFieldAPI() {
  yield takeEvery(UPDATE_COMMON_CUSTOM_FIELD, function* ({ payload }) {
    try {
      const {
        data: {
          data: { code },
        },
      } = yield call(AuthService.getOneTimeCodeCommon, {
        email: env.SYSTEM_EMAIL,
        password: env.SYSTEM_PASSWORD,
      })

      const data = yield call(AuthService.commonLogin, { code })

      payload.access_token = data.data.access_token

      const customField = yield call(
        CustomFieldService.updateCommonCustomField,
        payload
      )

      yield put(
        createLoginActivity(
          `Update Custom Field in Custom Field. CustomFieldCode - ${customField.data.CustomFieldCode}`
        )
      )

      setNotification({
        type: "success",
        message: data?.data?.message ?? "Updated custom field.",
      })
    } catch (err) {
      setError("Update Custom Field Failed", err.response.data.message ?? "")
    }
  })
}

export function* deleteCommonCustomFieldAPI() {
  yield takeEvery(DELETE_COMMON_CUSTOM_FIELD, function* ({ payload }) {
    try {
      const {
        data: {
          data: { code },
        },
      } = yield call(AuthService.getOneTimeCodeCommon, {
        email: env.SYSTEM_EMAIL,
        password: env.SYSTEM_PASSWORD,
      })

      const data = yield call(AuthService.commonLogin, { code })

      payload.access_token = data.data.access_token

      yield call(CustomFieldService.deleteCommonCustomField, payload)

      yield put(
        createLoginActivity(
          `Delete Custom Field in Custom Field. CustomFieldCode - ${payload.CustomFieldCode}`
        )
      )

      setNotification({
        type: "success",
        message: data?.data?.message ?? "Deleted custom field.",
      })
    } catch (err) {
      setError("Update Custom Field Failed", err.response.data.message ?? "")
    }
  })
}

export function* addCrmCustomFieldAPI() {
  yield takeEvery(INSERT_CRM_CUSTOM_FIELD, function* ({ payload }) {
    try {
      yield call(CustomFieldService.addCrmCustomField, payload)
    } catch (err) {
      setError("Add Custom Field Failed", err.response.data.message ?? "")
    }
  })
}

export function* updateCrmCustomFieldAPI() {
  yield takeEvery(UPDATE_CRM_CUSTOM_FIELD, function* ({ payload }) {
    try {
      yield call(CustomFieldService.updateCrmCustomField, payload)
    } catch (err) {
      setError("Update Custom Field Failed", err.response.data.message ?? "")
    }
  })
}

export function* listCrmCustomFieldAPI() {
  yield takeEvery(GET_CRM_CUSTOM_FIELD_LIST, function* ({ payload }) {
    try {
      const { data } = yield call(CustomFieldService.getCrmCustomField, payload)

      yield put(setCrmCustomFieldList(data))
    } catch (err) {
      setError("Listing Failed", err.response.data.message ?? "")
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(listCustomFieldAPI),
    fork(addCrmCustomFieldAPI),
    fork(updateCrmCustomFieldAPI),
    fork(addCommonCustomFieldAPI),
    fork(updateCommonCustomFieldAPI),
    fork(deleteCommonCustomFieldAPI),
    fork(listCrmCustomFieldAPI),
  ])
}
