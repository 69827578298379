import { CUSTOMER_TOKEN } from "redux/constants/Auth"
import {
  SET_CUSTOMER_PROFILE,
  SET_PASSCODE_ERROR,
  SHOW_CREATE_CUSTOMER,
  SHOW_UPDATE_CUSTOMER,
} from "redux/constants/SelfService"

const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "/self-service",
  token: localStorage.getItem(CUSTOMER_TOKEN),
  customer: {},
  hasPasscodeFetched: false,
  isPasscodeError: false,
}

const selfService = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_CREATE_CUSTOMER:
      return {
        ...state,
        loading: false,
      }
    case SHOW_UPDATE_CUSTOMER:
      return {
        ...state,
        loading: false,
        customer: {},
        token: localStorage.getItem(CUSTOMER_TOKEN),
      }
    case SET_CUSTOMER_PROFILE:
      return {
        ...state,
        customer: payload,
      }
    case SET_PASSCODE_ERROR:
      return {
        ...state,
        hasPasscodeFetched: payload.isFetched,
        isPasscodeError: payload.isError,
      }
    default:
      return state
  }
}

export default selfService
