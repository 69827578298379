import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const RewardObjectService = {}

RewardObjectService.createRewardObject = function (data) {
  return fetch({
    url: `/reward/objects/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

RewardObjectService.getRewardObject = function ({ rows, page, search }) {
  return fetch({
    url: `/reward/objects/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: rows || 100,
      Page: page || 1,
      Search: JSON.stringify(search ?? {}),
    },
  })
}

RewardObjectService.updateRewardObject = function ({ id, data }) {
  return fetch({
    url: `/reward/objects/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...data, RewardObjectId: id },
  })
}

RewardObjectService.deleteRewardObject = function (id) {
  return fetch({
    url: `/reward/objects/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { RewardObjectId: id },
  })
}

export default RewardObjectService
