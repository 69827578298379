import moment from "moment"
import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const CustomerService = {}

CustomerService.createCustomer = function (data) {
  if (data.MemberType === "Premium Member") {
    return fetch({
      url: "/customer/customers/premium/add",
      method: "post",
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
      data: data,
    })
  } else {
    return fetch({
      url: "/customer/customers/add",
      method: "post",
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
      data: data,
    })
  }
}

// TODO: Move functions to self service
CustomerService.createCustomerSelfService = function (data) {
  return fetch({
    url: "/customer/customers/premium/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}
//

CustomerService.getListCustomer = function (data) {
  let searchquery = { ...data }
  delete searchquery.rows
  delete searchquery.page
  delete searchquery.access_token

  if (data.search) {
    searchquery = { ...searchquery, ...data.search }
  }

  const payload = {
    Rows: data.rows ? data.rows : 20,
    Page: data.page ? data.page : 1,
    Search: JSON.stringify(searchquery),
  }

  return fetch({
    url: "/customer/customers/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

CustomerService.getCustomer = function (data) {
  return fetch({
    url: `/customer/customers/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CustomerId: data?.CustomerId },
  })
}

CustomerService.updateCustomer = function (data) {
  if (data.MemberType === "Premium Member" && data.isMemberTypeChanged) {
    return fetch({
      url: `customer/customers/premium/upgrade`,
      method: "post",
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
      data,
    })
  } else {
    return fetch({
      url: `customer/customers/update`,
      method: "post",
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
      data,
    })
  }
}

CustomerService.deleteCustomer = function (data) {
  return fetch({
    url: `/customer/customers/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CustomerId: data.CustomerId },
  })
}

CustomerService.getCurrentCustomer = function (mbsno) {
  return fetch({
    url: "/customer/memberships/find-one",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: { mbsno },
  })
}

CustomerService.getCustomerMembershipDetails = async function (data) {
  const { id, value, search } = data
  let FullName = "Record not found, please check"
  let CurrentBalance = "Record not found, please check"
  let AccumulatedBalance = "Record not found, please check"
  let MembershipNo = null
  let customerMembership = {}
  let customerBalances = []
  let customers = []
  let customerMemberships = []
  let Status

  try {
    if (id === "MembershipNo") {
      MembershipNo = value
      customerMembership = await fetch({
        url: `/customer/memberships/find-one`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
        method: "post",
        data: { mbsno: value },
      })

      customerBalances = await fetch({
        url: `/customer/memberships/balances`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
        method: "post",
        data: { MembershipNo: value, Search: JSON.stringify(search ?? {}) },
      })
    } else {
      customers = await fetch({
        url: "/customer/customers/find-all",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
        method: "post",
        data: {
          Rows: data.rows ? data.rows : 20,
          Page: data.page ? data.page : 1,
          Search: JSON.stringify({ [id]: value }),
        },
      })

      customerMemberships = await fetch({
        url: "/customer/memberships/find-all",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
        method: "post",
        data: {
          Rows: data.rows ? data.rows : 20,
          Page: data.page ? data.page : 1,
          Search: JSON.stringify({
            CustomerId: customers.data.rows[0].CustomerId,
          }),
        },
      })

      customerBalances = await fetch({
        url: `/customer/memberships/balances`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
        method: "post",
        data: {
          MembershipNo: customerMemberships.data.rows[0].MembershipNo,
          Search: JSON.stringify(search ?? {}),
        },
      })
    }

    if (customerMembership?.data?.Status) {
      Status = customerMembership.data.Status
    } else if (customers?.data?.rows[0]?.Status) {
      Status = customers?.data?.rows[0]?.Status
    }

    if (customerMemberships?.data?.rows[0]?.MembershipNo) {
      MembershipNo = customerMemberships.data.rows[0].MembershipNo
    }

    if (customers?.data?.rows[0]?.FullName) {
      FullName = customers.data.rows[0].FullName
    } else if (customerMembership?.data?.Customer?.FullName) {
      FullName = customerMembership.data.Customer.FullName
    }

    if (customerBalances?.data[0]?.CurrentBalance !== null) {
      CurrentBalance = customerBalances.data[0].CurrentBalance
    }

    if (customerBalances?.data[0]?.AccumulatedBalance !== null) {
      AccumulatedBalance = customerBalances.data[0].AccumulatedBalance
    }

    return {
      MembershipNo,
      FullName,
      CurrentBalance,
      AccumulatedBalance,
      Status,
    }
  } catch (e) {
    return {
      MembershipNo,
      FullName,
      CurrentBalance,
      AccumulatedBalance,
      Status,
    }
  }
}

CustomerService.getCustomerMembershipMergeDetails = async function (params) {
  return await fetch({
    url: "/customer/memberships/find-all",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    method: "post",
    data: {
      Rows: 999,
      Page: 1,
      Search: JSON.stringify(params ?? {}),
    },
  })
}

CustomerService.customerLogin = function (data, token) {
  return fetch({
    url: "/customer/login/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
}

CustomerService.findOneCustomerLogin = function (data, token) {
  return fetch({
    url: "/customer/login/find-one",
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
}

CustomerService.updateCustomerLogin = function (data) {
  const payload = { ...data, CustomerId: data.id }

  if (payload.id) delete payload.id
  if (payload.access_token) delete payload.access_token

  return fetch({
    url: "/customer/login/update",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: payload,
  })
}

CustomerService.updateCustomerPassword = function (data) {
  return fetch({
    url: "/customer/customers/" + data.id,
    method: "put",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

CustomerService.upgradeToPremium = function (data) {
  return fetch({
    url: `customer/customers/premium/upgrade`,
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data,
  })
}

CustomerService.getCardLevelDetail = function (data) {
  const searchQuery = { Status: "Active" }

  if (data.FirstSalesAmount) {
    searchQuery.FirstSalesAmount = parseFloat(data.FirstSalesAmount)
  }

  const payload = {
    Search: JSON.stringify(searchQuery),
  }

  return fetch({
    url: `member/card-levels/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: payload,
  })
}

CustomerService.verifyReferrer = function (data) {
  const payload = {}

  payload.TransactionDateFrom = moment("2020-01-01").format("YYYY-MM-DD")
  payload.TransactionDateTo = moment().format("YYYY-MM-DD")

  payload.AmountFrom = 1
  payload.AmountTo = 9999999
  payload.ExcludeCancelTrxYN = "Y"

  if (data?.MembershipNo) payload.MembershipNo = data.MembershipNo

  return fetch({
    url: `reporting/customer-transaction-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: payload,
  })
}

CustomerService.mergePremiumCustomerMembership = function (data) {
  return fetch({
    url: `customer/customers/premium/merge`,
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data,
  })
}

export default CustomerService
