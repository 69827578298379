import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const CampaignService = {}

CampaignService.getListCampaign = function (data) {
  const searchquery = {}
  if (data?.CampaignName) searchquery.CampaignName = data.CampaignName
  if (data?.CampaignCode) searchquery.CampaignCode = data.CampaignCode
  if (data?.CampaignType && data?.CampaignType !== "All")
    searchquery.CampaignType = data.CampaignType
  if (data?.StartDate) searchquery.StartDate = data.StartDate
  if (data?.EndDate) searchquery.EndDate = data.EndDate
  if (data?.Status && data?.Status !== "All") searchquery.Status = data.Status
  if (!data?.Status) searchquery.Status = "Active"

  return fetch({
    url: "/campaign/campaigns/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      Rows: data?.rows || 10,
      Page: data?.page || 1,
      Search: JSON.stringify(searchquery ?? {}),
    },
  })
}

CampaignService.getCampaign = function (id) {
  return fetch({
    url: `/campaign/campaigns/find-one`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { CampaignCode: id },
  })
}

CampaignService.deleteCampaign = function (CampaignCode) {
  return fetch({
    url: `/campaign/campaigns/delete`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { CampaignCode },
  })
}

CampaignService.createCampaign = function (data) {
  return fetch({
    url: `/campaign/campaigns/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      ...data,
      AwardPointYN: data?.BalanceType ? "Y" : "N",
      MinimumPurchase: `${data?.MinimumPurchase}`,
    },
  })
}

CampaignService.updateCampaign = function ({ campaignCode, data }) {
  return fetch({
    url: `/campaign/campaigns/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      ...data,
      AwardPointYN: data?.AwardPoint ? "Y" : "N",
      MinimumPurchase: `${data?.MinimumPurchase}`,
      CampaignCode: campaignCode,
    },
  })
}

export default CampaignService
