import { AUTH_TOKEN } from "redux/constants/Auth"
import moment from "moment"
import fetch from "auth/APIFetchInterceptor"

const ProfileUpdatedHistoryService = {}

async function getProfileUpdateHistory(data) {
  const payload = {
    Rows: data.rows ? data.rows : 10,
    Page: data.page ? data.page : 1,
    Search: JSON.stringify(data.queries ? data.queries : {}),
    CustomerId: data.CustomerId,
  }

  return fetch({
    url: `/customer/customers/updated-histories/find-all`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ProfileUpdatedHistoryService.createProfileUpdatedHistory = function (data) {
  return fetch({
    url: `/customer/customers/updated-histories/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

ProfileUpdatedHistoryService.updateProfileUpdatedHistory = function (data) {
  return fetch({
    url: `/customer/customers/updated-histories/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data,
  })
}

ProfileUpdatedHistoryService.listProfileUpdatedHistories = async function (
  data
) {
  let histories = {}
  if (data?.CustomerIds) {
    for (let CustomerId of data.CustomerIds) {
      const history = await getProfileUpdateHistory({ ...data, CustomerId })
      if (history) {
        histories = {
          count: (histories?.count ?? 0) + history?.data?.count,
          rows: [...(histories?.rows ?? []), ...history?.data?.rows],
        }
      }
    }
    histories = { data: histories }
  } else {
    histories = await getProfileUpdateHistory(data)
  }
  return histories
}

ProfileUpdatedHistoryService.listMemberProfileAmendment = function (data) {
  const searchQuery = {
    rows: 999,
    page: 1,
  }

  // DateFrom & DateTo as mandatory field
  searchQuery.DateFrom = moment(data?.CreatedAt).format("YYYY-MM-DD")
  searchQuery.DateTo = moment().format("YYYY-MM-DD")

  if (data.MemberType) searchQuery.MemberType = data.MemberType
  if (data.CustomerId) searchQuery.CustomerId = data.CustomerId

  // Profile Updated History search
  if (data.queries && Object.keys(data.queries)) {
    if (
      !(data.queries.ActionType === "" || data.queries.ActionType === "All")
    ) {
      searchQuery.ActionType = data.queries.ActionType
    }
    if (data.queries.UpdatedAt) {
      searchQuery.DateFrom = moment(data.queries.UpdatedAt[0]).format(
        "YYYY-MM-DD"
      )
      searchQuery.DateTo = moment(data.queries.UpdatedAt[1]).format(
        "YYYY-MM-DD"
      )
    }
    if (data.queries.UpdatedById)
      searchQuery.UpdatedById = data.queries.UpdatedById
  }

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/member-profile-amendment-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

export default ProfileUpdatedHistoryService
