import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  CREATE_CUSTOMER_LEAD_SOURCE,
  DELETE_CUSTOMER_LEAD_SOURCE,
  GET_LIST_CUSTOMER_LEAD_SOURCE,
  GET_CUSTOMER_LEAD_SOURCE_DETAIL,
  UPDATE_CUSTOMER_LEAD_SOURCE,
} from "redux/constants/CustomerLeadSource"
import setNotification from "components/shared-components/Notification"
import CustomerLeadSourceService from "services/CustomerLeadSourceService"
import {
  setListCustomerLeadSource,
  setCreateCustomerLeadSource,
  setUpdateCustomerLeadSource,
  setDeleteCustomerLeadSource,
  setCustomerLeadSourceDetail,
} from "redux/actions/CustomerLeadSource"

export function* createCustomerLeadSource() {
  yield takeEvery(CREATE_CUSTOMER_LEAD_SOURCE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const customerLeadSource = yield call(
        CustomerLeadSourceService.createCustomerLeadSource,
        payload
      )
      if (customerLeadSource.alert) {
        setNotification({
          type: "error",
          message: "Create Lead Source Failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Add Lead Source in Lead Source. LeadSourceId - ${customerLeadSource.data?.LeadSourceId}`
          )
        )

        yield put(setCreateCustomerLeadSource(customerLeadSource.data))
        setNotification({
          type: "success",
          message: "Create Lead Source Success",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Create Lead Source Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Create Lead Source Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* getAllCustomerLeadSource() {
  yield takeEvery(GET_LIST_CUSTOMER_LEAD_SOURCE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const customerLeadSource = yield call(
        CustomerLeadSourceService.getListCustomerLeadSource,
        payload
      )
      if (customerLeadSource.alert) {
        setNotification({
          type: "error",
          message: "Get Lead Source Failed",
          description: "An error occured",
        })
      } else {
        yield put(setListCustomerLeadSource(customerLeadSource.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Lead Source Failed",
        description: "An error occured",
      })
    }
  })
}

export function* getOneCustomerLeadSource() {
  yield takeEvery(GET_CUSTOMER_LEAD_SOURCE_DETAIL, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const customerLeadSource = yield call(
        CustomerLeadSourceService.getOneCustomerLeadSource,
        payload
      )

      if (customerLeadSource.alert) {
        setNotification({
          type: "error",
          message: "Get Lead Source Failed",
          description: "An error occured",
        })
      } else {
        yield put(setCustomerLeadSourceDetail(customerLeadSource.data))
      }
    } catch (error) {
      setNotification({
        type: "error",
        message: "Get Lead Source Failed",
        description: "An error occured",
      })
    }
  })
}

export function* updateCustomerLeadSource() {
  yield takeEvery(UPDATE_CUSTOMER_LEAD_SOURCE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const customerLeadSource = yield call(
        CustomerLeadSourceService.updateCustomerLeadSource,
        payload
      )
      if (customerLeadSource.alert) {
        setNotification({
          type: "error",
          message: "Update Lead Source failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Update Lead Source in Lead Source. LeadSourceId - ${customerLeadSource.data?.LeadSourceId}`
          )
        )

        yield put(setUpdateCustomerLeadSource(customerLeadSource.data))
        setNotification({
          type: "success",
          message: "Lead Source successfully updated",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Update Lead Source Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Lead Source Failed",
          description: error.message,
        })
      }
    }
  })
}

export function* deleteCustomerLeadSource() {
  yield takeEvery(DELETE_CUSTOMER_LEAD_SOURCE, function* ({ payload }) {
    let auth_token = localStorage.getItem("auth_token")
    payload.access_token = auth_token
    try {
      const customerLeadSource = yield call(
        CustomerLeadSourceService.deleteCustomerLeadSource,
        payload
      )
      if (customerLeadSource.alert) {
        setNotification({
          type: "error",
          message: "Delete Lead Source failed",
          description: "An error occured",
        })
      } else {
        yield put(
          createLoginActivity(
            `Delete Lead Source in Lead Source. LeadSourceId - ${payload.LeadSourceId}`
          )
        )

        yield put(setDeleteCustomerLeadSource(payload))
        setNotification({
          type: "success",
          message: "Lead Source successfully deleted",
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Delete Lead Source Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Delete Lead Source Failed",
          description: error.message,
        })
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createCustomerLeadSource),
    fork(getAllCustomerLeadSource),
    fork(getOneCustomerLeadSource),
    fork(updateCustomerLeadSource),
    fork(deleteCustomerLeadSource),
  ])
}
