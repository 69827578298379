import { all, takeEvery, put, fork, call } from "redux-saga/effects"
import {
  CREATE_MEMBERSHIP_LEVEL,
  GET_MEMBERSHIP_LEVELS,
  GET_MEMBERSHIPS_FOR_DOWNGRADE,
  GET_MEMBERSHIP_LEVEL_BY_MEMBER,
  UPDATE_MEMBERSHIP_LEVEL,
  APPROVE_MEMBERSHIP_LEVEL,
  REJECT_MEMBERSHIP_LEVEL,
  DELETE_MEMBERSHIP_LEVEL,
} from "../constants/MembershipLevel"
import {
  resultMembershipLevel,
  alertMembershipLevel,
} from "../actions/MembershipLevel"
import MembershipLevelService from "services/MembershipLevelService"
import { isArray } from "lodash"
import setNotification from "components/shared-components/Notification"
import AuthService from "services/AuthService"
import { CLIENT_IP_ADDRESS, UNIQUE_SESSION_ID } from "redux/constants/Auth"
import { createLoginActivity } from "redux/actions/Auth"

export function* createMembershipLevelAPI() {
  yield takeEvery(CREATE_MEMBERSHIP_LEVEL, function* ({ payload }) {
    const sessionId = localStorage.getItem(UNIQUE_SESSION_ID)
    const userIP = localStorage.getItem(CLIENT_IP_ADDRESS)
    let userInfo = JSON.parse(localStorage.getItem("userinfo"))

    try {
      const level = yield call(
        MembershipLevelService.createMembershipLevel,
        payload
      )
      if (level.alert) {
        yield put(
          alertMembershipLevel({ success: false, message: level.alert })
        )
      } else {
        setNotification({
          type: "success",
          message: `${payload.Action} Membership`,
          description: `${payload.Action} Membership Success`,
        })
      }

      /** Add Login Activity */
      if (payload.Action === "Downgrade") {
        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: `Downgrade member. LevelHistoryId - ${level.data.LevelHistoryId}`,
        })
      } else if (payload.Action === "Upgrade") {
        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: `Upgrade member. LevelHistoryId - ${level.data.LevelHistoryId}`,
        })
      }

      if (payload.callback) {
        payload.callback()
      }
    } catch (err) {
      setNotification({
        type: "error",
        message: `${payload.Action} Membership`,
        description: `${payload.Action} Membership Failed`,
      })
    }
  })
}

export function* getMembershipLevelsAPI() {
  yield takeEvery(GET_MEMBERSHIP_LEVELS, function* ({ payload }) {
    try {
      const level = yield call(
        MembershipLevelService.getMembershipLevels,
        payload
      )
      if (level.alert) {
        yield put(alertMembershipLevel(level.alert))
      } else {
        level.type = "LIST"
        yield put(resultMembershipLevel(level))
      }
    } catch (err) {
      yield put(alertMembershipLevel(err))
    }
  })
}

export function* getMembershipsForDowngradeAPI() {
  yield takeEvery(GET_MEMBERSHIPS_FOR_DOWNGRADE, function* ({ payload }) {
    try {
      const level = yield call(
        MembershipLevelService.getMembershipsForDowngrade,
        payload
      )
      if (level.alert) {
        yield put(alertMembershipLevel(level.alert))
      } else {
        level.type = "LIST"
        yield put(resultMembershipLevel(level))
      }
    } catch (err) {
      yield put(alertMembershipLevel(err))
    }
  })
}

export function* getMembershipLevelByMemberAPI() {
  yield takeEvery(GET_MEMBERSHIP_LEVEL_BY_MEMBER, function* ({ payload }) {
    try {
      const level = yield call(
        MembershipLevelService.getMembershipLevelByMember,
        payload
      )
      if (level.alert) {
        yield put(alertMembershipLevel(level.alert))
      } else {
        level.type = "LIST"
        yield put(resultMembershipLevel(level))
      }
    } catch (err) {
      yield put(alertMembershipLevel(err))
    }
  })
}

export function* updateMembershipLevelAPI() {
  yield takeEvery(UPDATE_MEMBERSHIP_LEVEL, function* ({ payload }) {
    try {
      const level = yield call(
        MembershipLevelService.updateMembershipLevel,
        payload
      )
      if (level.alert) {
        yield put(
          alertMembershipLevel({ success: false, message: level.alert })
        )
      } else {
        yield put(
          alertMembershipLevel({
            success: true,
            message: "Membership Level has been saved successfully.",
          })
        )
      }
    } catch (err) {
      yield put(
        alertMembershipLevel({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* approveMembershipLevelAPI() {
  yield takeEvery(APPROVE_MEMBERSHIP_LEVEL, function* ({ payload }) {
    try {
      const level = yield call(
        MembershipLevelService.approveMembershipLevel,
        payload
      )
      if (level.alert) {
        yield put(
          alertMembershipLevel({ success: false, message: level.alert })
        )
      } else {
        yield put(
          createLoginActivity(
            "Approve upgrade Member Category in Member Category Approval"
          )
        )

        yield put(
          alertMembershipLevel({
            success: true,
            message: "Membership Level has been approved successfully.",
          })
        )
      }
    } catch (err) {
      yield put(
        alertMembershipLevel({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* rejectMembershipLevelAPI() {
  yield takeEvery(REJECT_MEMBERSHIP_LEVEL, function* ({ payload }) {
    try {
      const level = yield call(
        MembershipLevelService.rejectMembershipLevel,
        payload
      )
      if (level.alert) {
        yield put(
          alertMembershipLevel({ success: false, message: level.alert })
        )
      } else {
        yield put(
          createLoginActivity(
            "Reject upgrade Member Category in Member Category Approval"
          )
        )

        yield put(
          alertMembershipLevel({
            success: true,
            message: "Membership Level has been rejected successfully.",
          })
        )
      }
    } catch (err) {
      yield put(
        alertMembershipLevel({
          success: false,
          message: isArray(err.response.data?.message)
            ? err.response.data?.message[0]
            : err.response.data.message,
        })
      )
    }
  })
}

export function* deleteMembershipLevelAPI() {
  yield takeEvery(DELETE_MEMBERSHIP_LEVEL, function* ({ payload }) {
    try {
      const level = yield call(
        MembershipLevelService.deleteMembershipLevel,
        payload
      )
      if (level.alert) {
        yield put(
          alertMembershipLevel({ success: false, message: level.alert })
        )
      } else {
        level.type = "DELETE"
        level.data = payload
        yield put(resultMembershipLevel(level))
        yield put(
          alertMembershipLevel({
            success: true,
            message: "Membership Level has been deleted successfully.",
          })
        )
      }
    } catch (err) {
      yield put(alertMembershipLevel({ success: false, message: err }))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(createMembershipLevelAPI),
    fork(getMembershipLevelsAPI),
    fork(getMembershipsForDowngradeAPI),
    fork(getMembershipLevelByMemberAPI),
    fork(updateMembershipLevelAPI),
    fork(approveMembershipLevelAPI),
    fork(rejectMembershipLevelAPI),
    fork(deleteMembershipLevelAPI),
  ])
}
